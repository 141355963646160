import React, { Component, Fragment, ReactNode } from 'react';
import { DatePicker, Input, InputLabel, TextArea } from '_common/components';
import { TransProps, withTranslation } from 'react-i18next';
import Select, { Option } from '_common/components/Select/Select';
import { observer } from 'mobx-react';
import moment from 'moment';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { OrderStore } from 'stores';
import { CustomDivider, RowWrapper } from '../elements/elements';

import DirectoryStore from '_common/stores/directoryStore';
import { PURCHASE_DATE_ERRORS } from '_common/constants/date';
import { IDetailsStore } from 'types/mobxStores';
import {
  IRouteNavigator,
  IRouterMatch,
  EDETAILS_ADDRESS_FIELD,
} from 'types/core';
import { REASON_COMMENTS_REGEX } from '_common/constants/regex';
import {
  validateOnSpecialCharacters,
  fullWidthRule,
} from '_common/utils/validationUtils';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';

type Props = RouteComponentProps<IRouterMatch> &
  TransProps<any> & {
    form: FormInstance;
    detailsPageStore: IDetailsStore;
    orderStore: OrderStore;
    directoryStore: DirectoryStore;
    routeNavigator: IRouteNavigator;
    whiteLabeled: any;
    logFieldEvent: (eventName, fieldName, extraPayload?: object) => void;
    handleInputBlur: (fieldName: string) => () => void;
  };

@observer
class DetailsFields extends Component<Props> {
  validatePurchaseDate = current => {
    const {
      directoryStore: { purchaseWarrantyPeriodDays },
    } = this.props;
    return (
      current &&
      current < moment().subtract(purchaseWarrantyPeriodDays + 1, 'days')
    );
  };

  disableFutureDate = current => current && current > moment();

  handleDatePickerSelect = data => {
    this.props.logFieldEvent('selected date', 'purchaseDate', {
      purchaseDate: moment(data).format('DD-MM-YYYY'),
    });
  };

  shouldRenderReturnReasons = () => {
    const {
      directoryStore: { returnReasons },
    } = this.props;
    return (
      returnReasons &&
      returnReasons.enabled &&
      returnReasons.reasons &&
      returnReasons.reasons.length &&
      returnReasons.reasons.some(({ enabled }) => enabled)
    );
  };

  handleSelect = (value: string, { props: { name } }) => {
    const payload = {
      ...this.props.form.getFieldsValue([name]),
      [name]: value,
    };
    this.props.logFieldEvent('clicking an option', name, payload);
  };

  renderSplitName = () => {
    const {
      whiteLabeled: {
        hasKanna,
        lastNameText,
        lastNamePlaceholder,
        firstNameText,
        firstNamePlaceholder,
        kannaLastNameText,
        kannaLastNamePlaceholder,
        kannaFirstNamePlaceholder,
        whitelabeledRules,
      },
      t,
      handleInputBlur,
    } = this.props;
    return (
      <>
        <RowWrapper childrenWidth={hasKanna ? '23%' : '48%'}>
          <Form.Item
            rules={whitelabeledRules.lastName}
            name="lastName"
            label={
              <InputLabel htmlFor="lastName">
                {t(`wl:${lastNameText}`)}*
              </InputLabel>
            }
          >
            <Input
              placeholder={t(`wl:${lastNamePlaceholder}`)}
              onBlur={handleInputBlur('lastName')}
            />
          </Form.Item>
          <Form.Item
            rules={whitelabeledRules.firstName}
            name="firstName"
            label={
              !hasKanna && (
                <InputLabel htmlFor="firstName">
                  {t(`wl:${firstNameText}`)}*
                </InputLabel>
              )
            }
          >
            <Input
              placeholder={t(`wl:${firstNamePlaceholder}`)}
              onBlur={handleInputBlur('firstName')}
              shifted={hasKanna}
            />
          </Form.Item>
          {hasKanna && (
            <>
              <Form.Item
                rules={whitelabeledRules.kannaLastName}
                name="kannaLastName"
                label={
                  <InputLabel htmlFor="kannaLastName">
                    {t(`wl:${kannaLastNameText}`)}*
                  </InputLabel>
                }
              >
                <Input
                  placeholder={t(`wl:${kannaLastNamePlaceholder}`)}
                  onBlur={handleInputBlur('kannaLastName')}
                />
              </Form.Item>
              <Form.Item
                rules={whitelabeledRules.kannaFirstName}
                name="kannaFirstName"
              >
                <Input
                  placeholder={t(`wl:${kannaFirstNamePlaceholder}`)}
                  onBlur={handleInputBlur('kannaFirstName')}
                  shifted
                />
              </Form.Item>
            </>
          )}
        </RowWrapper>
      </>
    );
  };

  renderFullName = (appendedField: ReactNode = null) => {
    const {
      whiteLabeled: { whitelabeledRules },
      t,
      handleInputBlur,
    } = this.props;
    return (
      <RowWrapper childrenWidth={appendedField ? '48%' : '100%'}>
        <Form.Item
          rules={whitelabeledRules.fullName}
          name="fullName"
          label={
            <InputLabel htmlFor="fullName">{t('fullnameLabel')}*</InputLabel>
          }
        >
          <Input
            placeholder={t('fullnamePlaceholder')}
            onBlur={handleInputBlur('fullName')}
          />
        </Form.Item>
        {appendedField}
      </RowWrapper>
    );
  };

  [EDETAILS_ADDRESS_FIELD.CITY] = () => {
    const {
      whiteLabeled: { cityText, cityPlaceholder, whitelabeledRules },
      t,
      handleInputBlur,
    } = this.props;
    return (
      <Form.Item
        rules={whitelabeledRules.city}
        name="city"
        label={<InputLabel htmlFor="city">{t(`wl:${cityText}`)}*</InputLabel>}
        key="city"
      >
        <Input
          placeholder={t(`wl:${cityPlaceholder}`)}
          onBlur={handleInputBlur('city/suburb')}
        />
      </Form.Item>
    );
  };

  [EDETAILS_ADDRESS_FIELD.ADDRESS1] = () => {
    const {
      whiteLabeled: { whitelabeledRules, address1Label, address1Placeholder },
      t,
      handleInputBlur,
    } = this.props;
    return (
      <Form.Item
        rules={whitelabeledRules.addressLine1}
        name="addressLine1"
        label={
          <InputLabel htmlFor="addressLine1">
            {t(`wl:${address1Label}`)}*
          </InputLabel>
        }
        key="addressLine1"
      >
        <Input
          placeholder={t(`wl:${address1Placeholder}`)}
          onBlur={handleInputBlur('addressLine1')}
        />
      </Form.Item>
    );
  };

  [EDETAILS_ADDRESS_FIELD.ADDRESS2] = () => {
    const {
      whiteLabeled: { whitelabeledRules, address2Label, address2Placeholder },
      t,
      handleInputBlur,
    } = this.props;
    return (
      <Form.Item
        rules={whitelabeledRules.addressLine2}
        name="addressLine2"
        label={
          <InputLabel htmlFor="addressLine2">
            {t(`wl:${address2Label}`)}
          </InputLabel>
        }
        key="addressLine2"
      >
        <Input
          placeholder={t(`wl:${address2Placeholder}`)}
          onBlur={handleInputBlur('addressLine2')}
        />
      </Form.Item>
    );
  };

  [EDETAILS_ADDRESS_FIELD.STATE] = () => {
    const {
      whiteLabeled: {
        isAreaFreeText,
        areaLabel,
        areaPlaceholder,
        countryStates,
        whitelabeledRules,
        getStateLabel,
      },
      t,
      handleInputBlur,
    } = this.props;
    return (
      <Fragment key="state">
        {isAreaFreeText ? (
          <Form.Item
            label={
              <InputLabel htmlFor="state">{t(`wl:${areaLabel}`)}*</InputLabel>
            }
            rules={whitelabeledRules.area}
            name="state"
          >
            <Input
              placeholder={t(`wl:${areaPlaceholder}`)}
              onBlur={handleInputBlur('state')}
            />
          </Form.Item>
        ) : (
          <Form.Item
            rules={[
              {
                whitespace: true,
                required: true,
                message: t('stateError'),
              },
            ]}
            name="state"
            label={
              <InputLabel htmlFor="state">{t(`wl:${areaLabel}`)}*</InputLabel>
            }
          >
            <Select
              placeholder={t(`wl:${areaPlaceholder}`)}
              onSelect={this.handleSelect}
              dropdownClassName="rpc-state-select"
            >
              {countryStates.map(item => (
                <Option
                  key={item.value}
                  value={item.value}
                  title={`${item.label} (${item.value})`}
                >
                  {getStateLabel(item)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Fragment>
    );
  };

  [EDETAILS_ADDRESS_FIELD.ZIP] = () => {
    const {
      whiteLabeled: {
        isZipVisible,
        zipCodeLength,
        zipCodePlaceholder,
        zipCodeText,
        whitelabeledRules,
      },
      t,
      handleInputBlur,
    } = this.props;

    return (
      <Fragment key="postcode">
        {isZipVisible && (
          <Form.Item
            rules={whitelabeledRules.zipCode}
            name="postcode"
            label={
              <InputLabel htmlFor="postcode">
                {t(`wl:${zipCodeText}`)}*
              </InputLabel>
            }
            key="postcode"
          >
            <Input
              placeholder={t(`wl:${zipCodePlaceholder}`)}
              maxLength={zipCodeLength}
              onBlur={handleInputBlur('postcode')}
            />
          </Form.Item>
        )}
      </Fragment>
    );
  };

  [EDETAILS_ADDRESS_FIELD.PHONE] = () => {
    const {
      directoryStore: { returnFormFields },
      t,
      handleInputBlur,
      whiteLabeled: { whitelabeledRules },
    } = this.props;
    return (
      <Fragment key="phoneNumber">
        {returnFormFields && returnFormFields.phoneNumber && (
          <Form.Item
            rules={whitelabeledRules.phoneNumber}
            name="phoneNumber"
            label={
              <InputLabel htmlFor="phoneNumber">{t('mobileLabel')}*</InputLabel>
            }
          >
            <Input
              placeholder={t('mobilePlaceholder')}
              onBlur={handleInputBlur('phoneNumber')}
            />
          </Form.Item>
        )}
      </Fragment>
    );
  };

  renderField = (fieldName: EDETAILS_ADDRESS_FIELD) => {
    if (!this[fieldName]) {
      console.error(
        'Invalid orderedFiledNames config value, please check WL config provided!'
      );
      return null;
    }
    return this[fieldName]();
  };

  renderAddressFields = (skipFirst = false) => {
    const {
      whiteLabeled: { orderedFiledNames },
    } = this.props;
    return orderedFiledNames
      .slice(skipFirst ? 1 : 0)
      .map(coupleFields => (
        <RowWrapper key={`${coupleFields[0]}-${coupleFields[1]}`}>
          {coupleFields.map((fieldName: EDETAILS_ADDRESS_FIELD) =>
            this.renderField(fieldName)
          )}
        </RowWrapper>
      ));
  };

  renderNameAndAddress = () => {
    const {
      whiteLabeled: { splitName, orderedFiledNames },
    } = this.props;
    let fieldToAppend = null;
    let skipFirst = false;
    if (orderedFiledNames[0].length === 1 && !splitName) {
      fieldToAppend = this.renderField(orderedFiledNames[0][0]);
      skipFirst = true;
    }
    return (
      <>
        {splitName
          ? this.renderSplitName()
          : this.renderFullName(fieldToAppend)}
        {this.renderAddressFields(skipFirst)}
      </>
    );
  };

  render() {
    if (!this.props.form) {
      return null;
    }

    const {
      form: { getFieldError },
      directoryStore: { returnReasons, returnFormFields },
      whiteLabeled: {
        dateFormates,
        reasonCommentsRequiredText,
        handlingInstructions,
        hasItemName,
        purchaseDateLabel,
        purchaseDatePlaceholder,
        purchaseDateRequiredErr,
        returnReasonRequiredErr,
      },
      t,
      handleInputBlur,
    } = this.props;
    const reasonsCommentLabel = `${
      returnFormFields.reasonCommentsFieldName || t('reasonCommentLabel')
    }${returnFormFields.reasonCommentsMandatory ? '*' : ''}`;

    const { reasons } = returnReasons;

    return (
      <>
        <RowWrapper>
          {returnFormFields && returnFormFields.purchaseDate && (
            <Form.Item
              rules={[
                {
                  validator: (
                    _,
                    date: string,
                    callback: (msg?: string) => void
                  ) => {
                    if (!date) {
                      return callback(t(`wl:${purchaseDateRequiredErr}`));
                    }
                    return this.validatePurchaseDate(moment(date))
                      ? callback(
                          t(`constants:${PURCHASE_DATE_ERRORS.FULL_MESSAGE}`)
                        )
                      : callback();
                  },
                },
              ]}
              name="purchaseDate"
              label={
                <InputLabel htmlFor="purchaseDate">
                  {t(`wl:${purchaseDateLabel}`)}*
                </InputLabel>
              }
            >
              <DatePicker
                currentLanguage={this.props.i18n.language}
                form={this.props.form}
                name="purchaseDate"
                format={dateFormates}
                mode="date"
                dateErrors={getFieldError('purchaseDate')}
                disabledDate={this.disableFutureDate}
                placeholder={t(`wl:${purchaseDatePlaceholder}`)}
                onChange={this.handleDatePickerSelect}
                fullWidth
              />
            </Form.Item>
          )}
          {this.shouldRenderReturnReasons() && (
            <Form.Item
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: t(`wl:${returnReasonRequiredErr}`),
                },
              ]}
              name="returnReason"
              label={
                <InputLabel htmlFor="returnReason">
                  {t('returnReasonLabel')}*
                </InputLabel>
              }
            >
              <Select
                placeholder={t('reasonPlaceholder')}
                onSelect={this.handleSelect}
                dropdownClassName="rpc-reason-select"
              >
                {reasons.map(
                  ({ enabled, description, code }) =>
                    enabled && (
                      <Option key={code} value={code}>
                        {description}
                      </Option>
                    )
                )}
              </Select>
            </Form.Item>
          )}
        </RowWrapper>
        {returnFormFields && returnFormFields.reasonComments && (
          <RowWrapper>
            <Form.Item
              rules={[
                {
                  required: returnFormFields.reasonCommentsMandatory,
                  message: t(`wl:${reasonCommentsRequiredText}`),
                },
                {
                  validator: (
                    rule,
                    value: string,
                    callback: (error?: string) => void
                  ) => {
                    validateOnSpecialCharacters(
                      rule,
                      value,
                      callback,
                      REASON_COMMENTS_REGEX
                    );
                  },
                },
              ]}
              name="reasonComments"
              label={
                <InputLabel htmlFor="reasonComments">
                  {reasonsCommentLabel}
                </InputLabel>
              }
            >
              <TextArea
                rows={3}
                placeholder={
                  returnFormFields.reasonCommentsFieldName
                    ? null
                    : t('reasonCommentsPlaceholder')
                }
                onBlur={handleInputBlur('reasonComments')}
              />
            </Form.Item>
          </RowWrapper>
        )}
        <RowWrapper>
          {hasItemName && (
            <Form.Item
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: t('itemNameError.required'),
                },
                {
                  max: 17,
                  message: <p>{t('itemNameError.length')}</p>,
                },
                // @ts-ignore
                fullWidthRule(<p>{t('itemNameError.invalid')}</p>),
              ]}
              name="itemName"
              label={
                <InputLabel htmlFor="itemName">{t('itemName')}*</InputLabel>
              }
            >
              <Input
                placeholder={t('itemNamePlaceholder')}
                onBlur={handleInputBlur('itemName')}
              />
            </Form.Item>
          )}
          {handlingInstructions && (
            <Form.Item
              className="with-flex-grow-label"
              name="handlingInstructions"
              label={
                <InputLabel htmlFor="handlingInstructions">
                  {t('handlingInstructionsLabel')}
                </InputLabel>
              }
            >
              <Select
                onSelect={this.handleSelect}
                dropdownClassName="rpc-reason-select"
              >
                {handlingInstructions.map(instruction => (
                  <Option key={instruction.value} value={instruction.value}>
                    {instruction.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </RowWrapper>
        <CustomDivider
          isVisible={
            returnFormFields?.purchaseDate ||
            returnFormFields?.reasonComments ||
            this.shouldRenderReturnReasons()
          }
        />
        {this.renderNameAndAddress()}
      </>
    );
  }
}

export default compose(withTranslation(['details', 'constants']))(
  DetailsFields
);

import {
  Input,
  InputLabel,
  InputWithTooltip,
  Card,
  TermsLink,
  Checkbox,
} from '_common/components';
import { StartPageStore, ThemeStore, OrderStore } from 'stores';
import commonStoresActions from '_common/actions';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { DefaultTheme, withTheme } from 'styled-components';
import { localStore as storage, MOBX_STORES, START_EVENT_SEND } from 'storage';
import {
  getArrayOfErrors,
  getMerchantFromUrl,
  interpolateTranslation,
  isInternationalCountry,
} from '_common/utils';
import { withTranslation, TransProps, Trans } from 'react-i18next';
import { AsyncStatus, PAYMENT_TYPES } from '_common/constants/common';
import {
  ConfirmButton,
  FormErrorMessage,
  SubText,
  AdditionalInfo,
  CenteredItem,
  Centered,
  CheckboxWrapper,
} from '../elements';

import amplitude from '_common/utils/amplitude';
import DirectoryStore from '_common/stores/directoryStore';
import PageStates from '_common/components/PageStates';
import { StyledCardTitle } from '_common/components/Card/elements';
import {
  withWhitelabelProps,
  WhiteLabelConstants,
} from '_common/whitelabelConfig';
import { IRouterMatch, IRouteNavigator } from 'types/core';
import { get } from 'lodash';
import EmbedStore from '_common/stores/embedStore';
import {
  restrictedDoubleBytesRule,
  specialCharactersRule,
  validateByPreconditions,
  validateOnSpecialCharacters,
} from '_common/utils/validationUtils';

type Props = TransProps &
  RouteComponentProps<IRouterMatch> & {
    startPageStore: StartPageStore;
    directoryStore: DirectoryStore;
    themeStore: ThemeStore;
    orderStore: OrderStore;
    embedStore: EmbedStore;
    theme: DefaultTheme;
    whiteLabeled: any;
    setCurrentGoBackMethod: (cb?: Function) => void;
    routeNavigator: IRouteNavigator;
  };

interface State {
  isSubmitting: boolean;
}

@observer
class StartPage extends Component<Props, State> {
  static PAGE_NAME = 'Start page';

  formRef = React.createRef<FormInstance>();

  state = {
    isSubmitting: false,
  };

  componentDidMount() {
    const {
      embedStore: {
        prepopulatedParams: { orderId, email },
        embedded,
      },
      startPageStore: { setFormField, formFields },
    } = this.props;
    if (embedded && (orderId || email) && !formFields.email) {
      setFormField({
        ...(orderId ? { orderNumber: orderId } : null),
        ...(email ? { email } : null),
      });
      this.formRef.current.setFieldsValue({
        orderNumber: orderId,
        email,
      });
      this.formRef.current.validateFields().then(this.onSubmit);
    }
    this.props.setCurrentGoBackMethod();
    setTimeout(() => {
      if (!amplitude) {
        console.log('amplitude not init');
        return;
      }

      if (!storage.get(START_EVENT_SEND)) {
        const { href: url } = window.location;
        const { company: retailerName } = this.props.match.params;
        amplitude.logEvent('Start journey', {
          url,
          retailerName,
          domainNameReferrer: document.referrer.split('/')[2],
          fullUrlReferrer: document.referrer,
        });

        storage.set(START_EVENT_SEND, true);
      }
    }, 1000);
  }

  componentWillUnmount() {
    storage.remove(START_EVENT_SEND);
  }

  onFinishFailed = ({ errorFields }) => {
    const {
      startPageStore: { enableFormErrorMessage },
      whiteLabeled: { isValidationErrorsHidden },
    } = this.props;
    amplitude.logFormValidationErrors(errorFields, StartPage.PAGE_NAME);
    this.setState({ isSubmitting: false });
    return (
      !isValidationErrorsHidden &&
      enableFormErrorMessage(getArrayOfErrors(errorFields, 'START')[0])
    );
  };

  onSubmit = async values => {
    if (this.state.isSubmitting) {
      return;
    }

    this.setState({ isSubmitting: true });

    const {
      startPageStore: { enableFormErrorMessage },
      orderStore,
      t,
    } = this.props;

    /** extract company on root page */
    const company = getMerchantFromUrl();

    if (company) {
      commonStoresActions.saveToStorage(MOBX_STORES.StartPageStore, values);
      const productJourney = commonStoresActions.getProductJourneyType();
      // non-integrated  - don't call api
      if (
        !WhiteLabelConstants.IS_INTEGRATED_JOURNEY_AVAILABLE ||
        productJourney.NON_INTEGRATED ||
        productJourney.NOT_SUPPORTED
      ) {
        return this.props.routeNavigator.next();
      }
      try {
        await commonStoresActions.getOrderById(company);
        const country = get(orderStore.userInfo, 'country');
        if (isInternationalCountry(country)) {
          enableFormErrorMessage(t('internationalError'));
          return;
        }
        this.props.routeNavigator.next();
      } catch (error) {
        enableFormErrorMessage(error);
      } finally {
        this.setState({ isSubmitting: false });
      }
    }
  };

  handleConfirmClick = () => {
    const { href: url } = window.location;
    const { company: retailerName } = this.props.match.params;
    amplitude.logEvent('clicking confirm', {
      url,
      retailerName,
    });
  };

  handleInputBlur = (fieldName: string) => {
    const { href: url } = window.location;
    const { company: retailerName } = this.props.match.params;

    if (!this.props.startPageStore.formFields[fieldName]) return;

    return () => {
      amplitude.logEvent('data has been entered into form field', {
        url,
        fieldName,
        retailerName,
      });
    };
  };

  renderOrderIdInput = () => {
    const {
      directoryStore: {
        validationRegex,
        orderIdCustomHeader,
        productIntegratedJourney,
      },
      whiteLabeled: { getOrderIdRegex, isConsumerTextEnabled },
      theme: { config },
      t,
    } = this.props;
    const consumerTextEnabled = isConsumerTextEnabled(productIntegratedJourney);
    const tooltipText = (!consumerTextEnabled && config.orderInstructions) || (
      <Trans i18nKey="tooltipText" ns="start" values={{ spacer: '\n' }} />
    );
    const labelText =
      (!consumerTextEnabled && orderIdCustomHeader) || t('orderLabel');

    return (
      <Form.Item
        label={<InputLabel htmlFor="orderNumber">{labelText}</InputLabel>}
        key="orderNumber"
        name="orderNumber"
        rules={[
          { transform: value => value && value.trim() },
          {
            required: true,
            message: (
              <Trans
                i18nKey="orderRequiredError"
                ns="start"
                values={{ prefix: labelText }}
              />
            ),
          },
          validationRegex && {
            pattern: new RegExp(`^${validationRegex}$`),
            message: (
              <Trans
                i18nKey="orderInvalidError"
                ns="start"
                values={{ prefix: labelText }}
              />
            ),
          },
          {
            validator: (
              rule,
              value: string,
              callback: (error?: string) => void
            ) => {
              validateOnSpecialCharacters(
                rule,
                value,
                callback,
                getOrderIdRegex({ prefix: labelText })
              );
            },
          },
        ]}
      >
        <InputWithTooltip
          tooltipText={tooltipText}
          id="orderNumber"
          placeholder={interpolateTranslation({
            t,
            i18nKey: 'orderPlaceholder',
            replaceValues: { prefix: labelText },
          })}
          onBlur={this.handleInputBlur('orderNumber')}
        />
      </Form.Item>
    );
  };

  renderRmaInput = () => {
    const { t } = this.props;
    return (
      <Form.Item
        key="rmaNumber"
        name="rmaNumber"
        label={<InputLabel htmlFor="rmaNumber">{t('rmaLabel')}</InputLabel>}
        rules={[
          { transform: value => value && value.trim() },
          {
            required: true,
          },
          {
            validator: (
              rule,
              value: string,
              callback: (error?: string) => void
            ) => {
              validateOnSpecialCharacters(rule, value, callback);
            },
          },
        ]}
      >
        <Input
          id="rmaNumber"
          placeholder={t('rmaPlaceholder')}
          onBlur={this.handleInputBlur('rmaNumber')}
        />
      </Form.Item>
    );
  };

  renderRanNumberInput = () => {
    const {
      directoryStore: {
        returnFormFields: { RANFieldName },
        returnReasons: { RANValidationRegex },
      },
      whiteLabeled: { ranPlaceholder },
      t,
    } = this.props;
    return (
      <Form.Item
        key="ranNumber"
        name="ranNumber"
        label={
          <InputLabel htmlFor="ranNumber">
            {RANFieldName || t(`wl:${ranPlaceholder}`)}
          </InputLabel>
        }
        rules={[
          { transform: value => value && value.trim() },
          {
            required: true,
            message: t('ranError'),
          },
          {
            // @ts-ignore
            validator: validateByPreconditions({
              regex: {
                value: new RegExp(`^${RANValidationRegex}$`),
                message: 'Entry not in required format',
              },
            }),
          },
        ]}
      >
        <Input id="ranNumber" placeholder={t('ranPlaceholder')} />
      </Form.Item>
    );
  };

  renderEmailInput = () => {
    const { t } = this.props;
    return (
      <Form.Item
        key="email"
        name="email"
        label={<InputLabel htmlFor="email">{t('emailLabel')}</InputLabel>}
        validateFirst
        rules={[
          {
            required: true,
            message: t('emailRequiredError'),
          },
          {
            type: 'email',
            message: t('emailInvalidError'),
          },
          // @ts-ignore
          restrictedDoubleBytesRule(t('emailInvalidError')),
          specialCharactersRule,
        ]}
      >
        <Input
          id="email"
          placeholder={t('emailPlaceholder')}
          onBlur={this.handleInputBlur('email')}
        />
      </Form.Item>
    );
  };

  renderCheckbox = () => {
    const {
      directoryStore: { termsAndConditionsUrl },
      whiteLabeled: { confirmationCheckboxConfig },
    } = this.props;
    const { company: retailerName } = this.props.match.params;

    return (
      termsAndConditionsUrl && ( // temporal, will be covered when on admin field become required.
        <Centered key="checkbox">
          <Form.Item
            name="confirmTerms"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        confirmationCheckboxConfig.getRequiredMessage()
                      ),
              },
            ]}
          >
            <CheckboxWrapper>
              <Checkbox
                fieldError={this.formRef.current?.getFieldError('confirmTerms')}
              />
              <TermsLink
                label={confirmationCheckboxConfig.getLabel()}
                termsAndConditionsUrl={termsAndConditionsUrl}
                retailerName={retailerName}
              />
            </CheckboxWrapper>
          </Form.Item>
        </Centered>
      )
    );
  };

  generateInputs = () => {
    const {
      directoryStore: {
        rma,
        returnFormFields: { RAN, orderId },
      },
      whiteLabeled: { confirmationCheckboxConfig },
    } = this.props;
    const inputs = [];

    if (orderId) {
      inputs.push(this.renderOrderIdInput());
    }
    if (rma === 'PRE_SUPPLIED') {
      inputs.push(this.renderRmaInput());
    }
    if (RAN) {
      inputs.push(this.renderRanNumberInput());
    }
    inputs.push(this.renderEmailInput());

    if (confirmationCheckboxConfig) {
      inputs.push(this.renderCheckbox());
    }
    return inputs.map(jsx => jsx);
  };

  onValuesChange = fields => {
    const {
      startPageStore: { setFormField, enableFormErrorMessage },
    } = this.props;
    const keys = Object.keys(fields);
    if (!keys.length) {
      return;
    }
    setFormField(fields);
    enableFormErrorMessage('');
  };

  renderForm = () => {
    const {
      startPageStore: { showFormErrorMessage, formFields },
      whiteLabeled: { confirmationCheckboxConfig },
      t,
    } = this.props;

    return (
      <Form
        initialValues={
          confirmationCheckboxConfig
            ? { ...formFields, confirmTerms: false }
            : formFields
        }
        onFinish={this.onSubmit}
        onFinishFailed={this.onFinishFailed}
        onValuesChange={this.onValuesChange}
        ref={this.formRef}
        layout="vertical"
        requiredMark={false}
      >
        <FormErrorMessage hidden={!showFormErrorMessage}>
          {showFormErrorMessage}
        </FormErrorMessage>
        {this.generateInputs()}
        <CenteredItem>
          <ConfirmButton
            htmlType="submit"
            onClick={this.handleConfirmClick}
            disabled={this.state.isSubmitting}
          >
            {t('nextBtn')}
          </ConfirmButton>
        </CenteredItem>
      </Form>
    );
  };

  getTitleText = () => {
    const {
      directoryStore: {
        paymentConfig: { paymentType, price },
        returnFormFields,
      },
      whiteLabeled: { returnIsFreeText, costText },
      t,
    } = this.props;
    const { MERCHANT_PAID, CONSUMER_PAID, BOTH_PAID } = PAYMENT_TYPES;
    const translatedCostText = t(`wl:${costText}`);
    if (returnFormFields.returnPaymentTextDescription) {
      return returnFormFields.returnPaymentTextDescription;
    }

    switch (paymentType) {
      case MERCHANT_PAID:
        return t(`wl:${returnIsFreeText}`);
      case CONSUMER_PAID:
        return `${translatedCostText}${price}`;
      case BOTH_PAID:
        return t('titlePay');
      default:
        return t('titleUnable');
    }
  };

  renderLayout = () => {
    const {
      directoryStore: {
        termsAndConditionsUrl,
        returnFormFields,
        paymentConfig,
      },
      whiteLabeled: { titleText, subTitleText, confirmationCheckboxConfig },
      t,
    } = this.props;
    const { company: retailerName } = this.props.match.params;
    const paymentText = this.getTitleText();

    return (
      <Card>
        <StyledCardTitle>{t(`wl:${titleText}`)}</StyledCardTitle>
        {paymentConfig.isEnabled && returnFormFields?.returnPaymentText && (
          <AdditionalInfo>{paymentText}</AdditionalInfo>
        )}
        {subTitleText && <SubText breakLine>{t(`wl:${subTitleText}`)}</SubText>}
        {this.renderForm()}
        {!confirmationCheckboxConfig && termsAndConditionsUrl && (
          <CenteredItem>
            <TermsLink
              termsAndConditionsUrl={termsAndConditionsUrl}
              retailerName={retailerName}
            />
          </CenteredItem>
        )}
      </Card>
    );
  };

  render() {
    const {
      directoryStore: { status },
    } = this.props;

    switch (status) {
      case AsyncStatus.SUCCESS:
        return this.renderLayout();
      case AsyncStatus.FAILED:
        return <PageStates.PageError />;
      default:
        return null;
    }
  }
}

export default compose(
  inject(
    'startPageStore',
    'directoryStore',
    'themeStore',
    'orderStore',
    'embedStore'
  ),
  withTheme,
  withWhitelabelProps({
    getOrderIdRegex: 'ui.pages.start.getOrderIdRegex',
    titleText: 'ui.pages.start.titleText',
    subTitleText: 'ui.pages.start.subTitleText',
    returnIsFreeText: 'ui.pages.start.returnIsFreeText',
    ranPlaceholder: 'ui.pages.start.ranPlaceholder',
    costText: 'ui.pages.start.costText',
    isValidationErrorsHidden: 'ui.pages.start.isValidationErrorsHidden',
    confirmationCheckboxConfig: 'ui.pages.start.confirmationCheckboxConfig',
    isConsumerTextEnabled: 'ui.common.isConsumerTextEnabled',
  }),
  withTranslation(['start', 'wl'])
)(StartPage);

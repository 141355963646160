import {
  LOCATIONS_MAPPER,
  MAX_DISTANCE,
  MAX_STORES,
} from 'pages/locate/utils/locationUtils';
import { WhiteLabelUi } from '_common/whitelabelConfig';
import { ROOT_ORG_ID } from '_common/constants/common';
import qs from 'qs';

interface IGeoRequestConfig {
  lat: number;
  lng: number;
  locationTypes: string;
  distance?: number;
  extendedHours: boolean;
  acceptsPaperless?: boolean;
  limit?: number;
  services?: string;
  company?: string;
}
export const getGeoUrl = (config: IGeoRequestConfig): string => {
  const {
    lat,
    lng,
    locationTypes,
    distance = WhiteLabelUi.pages.locate?.defaultSearchDistance ?? MAX_DISTANCE,
    extendedHours,
    acceptsPaperless = false,
    limit = MAX_STORES,
    services = WhiteLabelUi.pages.locate?.defaultSearchServices ?? 'RETURNS',
    company,
  } = config;

  const queryString = qs.stringify(
    {
      limit,
      ...(locationTypes ? { locationTypes } : {}),
      extendedHours,
      ...(acceptsPaperless ? { acceptsPaperless } : {}),
      unit: WhiteLabelUi.pages.success.distanceUnit,
      distance,
      companyId: company ? company.toUpperCase() : ROOT_ORG_ID,
      services,
      ...(locationTypes === LOCATIONS_MAPPER.POST_OFFICES
        ? {}
        : { storesFeed: true }),
      includeOpeningHours: true,
    },
    { encode: false }
  );
  return `/v3/stores/latitude/${lat}/longitude/${lng}?${queryString}`;
};

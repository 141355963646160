import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Content,
  ContentBody,
  LatestStatus,
  LatestStatusDescription,
  LatestStatusSuccessIcon,
  MarketingImage,
  PrimaryText,
  SecondaryText,
} from '../elements';
import { Card, LoaderArc } from '_common/components';
import { compose } from 'recompose';
import { RouteComponentProps } from 'react-router-dom';
import { withTranslation, TransProps } from 'react-i18next';
import StatusTracker from 'pages/tracking/components/StatusTracker';
import { TrackersList } from 'pages/tracking/elements/tracker';
import { ThemeStore, TrackingStore } from 'stores';
import commonStoresActions from '_common/actions';
import qs from 'qs';
import { withWhitelabelProps } from '_common/whitelabelConfig';
import amplitude from '_common/utils/amplitude';
import { TrackingEventWithInternalState } from 'types/tracking';
import { CardSize } from '_common/components/Card/Card';
import { successStepIcon } from 'assets';
import { EAsyncStatus } from 'types/core';

type Props = RouteComponentProps<{ company: string }> &
  TransProps & {
    trackingStore: TrackingStore;
    themeStore: ThemeStore;
    whiteLabeled: any;
    setCurrentGoBackMethod: (cb?: Function) => void;
  };

type State = {
  trackingId: string;
};

@observer
class TrackingPage extends Component<Props, State> {
  static PAGE_NAME = 'Tracking page';

  constructor(props) {
    super(props);
    const { id: trackingId } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.state = {
      trackingId,
    };
  }

  componentDidMount() {
    this.props.setCurrentGoBackMethod();
    amplitude.logEventWithOrganisationAndUrl('page_open', {
      page_name: TrackingPage.PAGE_NAME,
    });
    commonStoresActions.getTrackingInfo(
      this.state.trackingId,
      this.props.match.params.company
    );
  }

  renderTrackers = () => {
    const {
      trackingStore: { events },
    } = this.props;
    return (
      <TrackersList>
        {[...events.values()].map(
          (event: TrackingEventWithInternalState, index, list) => (
            <StatusTracker
              key={event.eventType}
              {...event}
              index={index}
              list={list}
            />
          )
        )}
      </TrackersList>
    );
  };

  renderContent = () => {
    const {
      themeStore: {
        theme: {
          config: { marketingUrl, isMarketingAssetsVisible },
        },
      },
      trackingStore: { events, latestEvent },
      t,
    } = this.props;

    return (
      <>
        <ContentBody>
          <LatestStatus>
            <LatestStatusSuccessIcon>
              <img src={successStepIcon} alt="success icon" />
            </LatestStatusSuccessIcon>
            <LatestStatusDescription>
              <PrimaryText>
                {t(latestEvent) || t('stores:notFoundError')}
              </PrimaryText>
              <SecondaryText>{t('trackingNumber')}</SecondaryText>
              {/* Place on second line to avoid text direction issues */}
              <SecondaryText>{this.state.trackingId}</SecondaryText>
            </LatestStatusDescription>
          </LatestStatus>
          {events.size ? this.renderTrackers() : null}
        </ContentBody>
        {isMarketingAssetsVisible && (
          <MarketingImage href={marketingUrl} target="_blank" />
        )}
      </>
    );
  };

  render() {
    const {
      themeStore: {
        state: themeStoreAsyncStatus,
        theme: {
          config: { isMarketingAssetsVisible },
        },
      },
      trackingStore: { asyncStatus: trackingStoreAsyncStatus },
    } = this.props;

    const isLoading = [themeStoreAsyncStatus, trackingStoreAsyncStatus].some(
      status => status === EAsyncStatus.LOADING
    );

    return (
      <Card
        customSize={isMarketingAssetsVisible ? CardSize.LARGE : CardSize.SMALL}
        noPadding
      >
        <Content centered={isLoading}>
          {isLoading ? <LoaderArc /> : this.renderContent()}
        </Content>
      </Card>
    );
  }
}

export default compose(
  inject('trackingStore', 'themeStore'),
  withTranslation('tracking'),
  withWhitelabelProps({
    trackersList: 'ui.pages.tracking.trackersList',
    logo: 'ui.common.carrierLogo.src',
  })
)(TrackingPage);

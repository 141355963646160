import React from 'react';
import { compose } from 'recompose';
import { Holder, Pin, Content, StyledSubTitle, StyledOption } from './elements';
import { StyledCardTitle } from '_common/components/Card/elements';
import { EReturnType } from 'types/core';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import { withWhitelabelProps } from '_common/whitelabelConfig';
import { INestedKeyValueObject } from 'types/whitelabelTypes';

export interface Props {
  value: EReturnType;
  onSelect: (string) => void;
  isActive: boolean;
  translationName: string;
  t: TFunction;
  postcode?: string;
  whiteLabeled: {
    getUrlsConfig?: ({ postcode: string }) => INestedKeyValueObject;
  };
}

const ReturnOption = ({
  translationName,
  t,
  isActive,
  value,
  onSelect,
  postcode,
  whiteLabeled: { getUrlsConfig },
}: Props) => {
  const onMethodSelect = () => onSelect(value);

  const renderDetailsSection = () => {
    if (!getUrlsConfig) {
      return null;
    }

    const config = getUrlsConfig({ postcode });
    if (!config || !config[translationName]) {
      return null;
    }

    return (
      <>
        <StyledOption>{t(`${translationName}.details.heading`)}</StyledOption>
        {Object.entries(config[translationName]).map(([optionKey, href]) => (
          <StyledOption key={optionKey}>
            <Trans
              i18nKey={`${translationName}.details.${optionKey}`}
              ns="returnMethod"
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                1: <a target="_blank" rel="noopener noreferrer" href={href} />,
              }}
            />
          </StyledOption>
        ))}
      </>
    );
  };

  return (
    <Holder>
      <Pin onClick={onMethodSelect} isActive={isActive} />
      <Content>
        <StyledCardTitle>{t(`${translationName}.title`)}</StyledCardTitle>
        <StyledSubTitle>{t(`${translationName}.description`)}</StyledSubTitle>
        {renderDetailsSection()}
      </Content>
    </Holder>
  );
};

export default compose(
  withWhitelabelProps({
    getUrlsConfig: 'ui.pages.returnMethod.getUrlsConfig',
  })
)(ReturnOption);

import React, { Component } from 'react';
import { compose } from 'recompose';
import { WithTranslation, withTranslation } from 'react-i18next';

import { withWhitelabelProps } from '_common/whitelabelConfig';
import {
  Button,
  ClearButton,
  ErrorMessage,
  GeoIcon,
  Input,
  InputWrapper,
  LabelWrapper,
  Root,
  Select,
} from './SearchHeaderElements';
import { geoIcon } from 'assets';

const { Option } = Select;

type Props = WithTranslation & {
  whiteLabeled: any;
  parentRef?: React.RefObject<HTMLInputElement>;
  isLoading: boolean;
  hasError: boolean;
  isSearchButtonDisabled: boolean;
  defaultValue?: string;
  locationTypesOptions?: { label: string; value: string }[];
  onGeoIconClick: () => Promise<void>;
  onInputClick: () => void;
  onSearchBtnClick: () => Promise<void>;
  onInputChange: () => void;
  onClearBtnClick: () => void;
  onStoreTypeChange: () => void;
};

class SearchHeader extends Component<Props> {
  renderOption = (option: { value: string; label: string }, index: number) => {
    const { value, label } = option;
    if (!value || !label) {
      return null;
    }

    return (
      <Option key={`option-${index}`} value={value}>
        {this.props.t(`success:${label}`)}
      </Option>
    );
  };

  renderSelect() {
    const {
      t,
      whiteLabeled: { availableTypeOptions },
      locationTypesOptions,
      isLoading,
      onStoreTypeChange,
    } = this.props;
    const defaultValue = locationTypesOptions[0]?.label ?? null;
    const locationTypesOptsFiltered =
      availableTypeOptions && availableTypeOptions.length
        ? locationTypesOptions.filter(opt =>
            availableTypeOptions.includes(opt.value)
          )
        : locationTypesOptions;
    return (
      <Select
        key={defaultValue}
        defaultValue={defaultValue}
        placeholder={t('success:searchPlaceholder')}
        onChange={onStoreTypeChange}
        disabled={isLoading}
      >
        {locationTypesOptsFiltered.map(this.renderOption)}
      </Select>
    );
  }

  render() {
    const {
      t,
      isLoading,
      hasError,
      isSearchButtonDisabled,
      onGeoIconClick,
      onInputClick,
      onSearchBtnClick,
      onInputChange,
      defaultValue,
      onClearBtnClick,
      whiteLabeled: { isLocationsTypeSelectDisabled },
    } = this.props;

    const icon = (
      <GeoIcon
        image={geoIcon}
        onClick={onGeoIconClick}
        width={24}
        height={24}
        disabled={isLoading}
      />
    );

    return (
      <Root>
        <LabelWrapper />
        <InputWrapper>
          <div>
            <Input
              placeholder={t('success:searchIntPlaceholder')}
              ref={this.props.parentRef}
              onClick={onInputClick}
              disabled={isLoading}
              defaultValue={defaultValue}
              onChange={onInputChange}
            />
            <ClearButton onClick={onClearBtnClick}>
              {t('success:clearBtn')}
            </ClearButton>
            {icon}
          </div>
          {!isLocationsTypeSelectDisabled && this.renderSelect()}
          <Button
            onClick={onSearchBtnClick}
            disabled={isLoading || isSearchButtonDisabled}
          >
            {t('success:searchBtn')}
          </Button>
        </InputWrapper>
        {hasError && <ErrorMessage>{t('success:loadingError')}</ErrorMessage>}
      </Root>
    );
  }
}

export default compose(
  withWhitelabelProps({
    availableTypeOptions: 'ui.pages.success.availableTypeOptions',
    isLocationsTypeSelectDisabled:
      'ui.pages.locate.isLocationsTypeSelectDisabled',
  }),
  withTranslation(['success', 'locate'], { withRef: true })
)(
  React.forwardRef((props: any, ref) => (
    <SearchHeader {...props} parentRef={ref} />
  ))
);

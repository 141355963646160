import React from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { isIOS, isMobile } from 'react-device-detect';
import { get } from 'lodash';

import {
  LocationAddress,
  LocationDistance,
  LocationDistanceInfo,
  LocationInfo,
  LocationItem,
  LocationOhTableBody,
  LocationOhTableData,
  LocationOhTableRow,
  LocationOpeningHours,
  LocationOpeningHoursTitle,
  LocationSeeMoreBtn,
  LocationSubInfo,
  LocationTitle,
  ToolTipSmaller,
  TooltipText,
  ToolTipWrapper,
  LocationIconContainer,
} from './StoreItemElements';
import { DAYS } from '_common/constants/date';
import { printerDarkIcon, printerRedIcon } from 'assets';
import amplitude from '_common/utils/amplitude';
import { normalizeStoreName } from 'pages/locate/utils/locationUtils';
import { IStore, IStoreAddress } from 'types/store';
import {
  WhiteLabelUtils,
  WhiteLabelUi,
  withWhitelabelProps,
} from '_common/whitelabelConfig';
import LocationStore from '_common/stores/locationStore';
import { IDayDescription } from 'types/core';

type Props = WithTranslation & {
  isActive: boolean;
  store: IStore;
  locationStore?: LocationStore;
  onClick?: (store: IStore) => void;
  customerLogo?: string;
  currentTab?: 'map' | 'list';
  whiteLabeled: {
    getLocationAddress?: (address: IStoreAddress) => string;
    specialDayTypes?: IDayDescription[];
  };
};

type State = {
  seeMoreBtn: boolean;
};

@observer
class StoreItem extends React.Component<Props, State> {
  rootRef = React.createRef<HTMLDivElement>();

  state = {
    seeMoreBtn: false,
  };

  componentDidUpdate(props: Props) {
    if (
      isMobile &&
      this.props.currentTab === 'list' &&
      props.currentTab === 'map' &&
      this.props.locationStore.activeStoreId === this.props.store.storeId
    ) {
      this.rootRef.current.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    } else if (this.props.isActive && !props.isActive && this.rootRef.current) {
      this.rootRef.current.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  }

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.store);
    }
  };

  getFormattedTime = timeString =>
    `${timeString.substring(0, 2)}:${timeString.substring(2, 4)}`;

  getWorkingTimeText = ({ hours, isOpen }) => {
    if (!isOpen) {
      return this.props.t('constants:closed');
    }

    return hours
      .reduce((accumulated, [openTime, closeTime]) => {
        const timeRange = `${this.getFormattedTime(
          openTime
        )} - ${this.getFormattedTime(closeTime)}`;
        accumulated.push(timeRange);
        return accumulated;
      }, [])
      .join(' & ');
  };

  renderMoreStoreDetails = openingHours => {
    const {
      whiteLabeled: { specialDayTypes = [] },
      t,
    } = this.props;

    return (
      <>
        <LocationOpeningHoursTitle>
          {t('locate:openingHours')}
        </LocationOpeningHoursTitle>
        <table>
          <LocationOhTableBody>
            {[...DAYS, ...specialDayTypes].map(({ fullName, shortName }) => {
              const openingHoursConfig = openingHours[fullName];
              return (
                openingHoursConfig && (
                  <LocationOhTableRow key={fullName}>
                    <LocationOhTableData>{t(shortName)}</LocationOhTableData>
                    <LocationOhTableData>
                      {this.getWorkingTimeText(openingHoursConfig)}
                    </LocationOhTableData>
                  </LocationOhTableRow>
                )
              );
            })}
          </LocationOhTableBody>
        </table>
      </>
    );
  };

  seeMoreClickHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    const {
      store: { storeName, storeId },
    } = this.props;
    amplitude.logEventWithOrganisationAndUrl('clicked see more for store', {
      storeName,
      storeId,
    });

    this.setState(prevState => ({
      seeMoreBtn: !prevState.seeMoreBtn,
    }));
  };

  renderTooltipTitle = () => (
    <>
      <img src={printerRedIcon} alt="Printer logo" />
      <TooltipText>{this.props.t('locate:paperlessLabel')}</TooltipText>
    </>
  );

  get acceptsPaperless() {
    const {
      store: { services },
    } = this.props;
    return get(services, 'RETURNS.acceptsPaperless', false);
  }

  get normalizedStoreName() {
    const {
      store: { storeName, locationType },
    } = this.props;
    return normalizeStoreName(storeName, locationType);
  }

  render() {
    const {
      isActive,
      store: {
        locationInfo,
        place: {
          address,
          address: { line1, line2, area, postcode, town },
        },
        openingHours,
      },
      customerLogo,
      t,
      whiteLabeled: { getLocationAddress },
    } = this.props;
    const distance = this.props.locationStore.getDistanceToLastSearch(
      locationInfo
    );

    let place = 'right';

    if (isIOS || isMobile) {
      place = 'left';
    }

    const showPrintIcon = WhiteLabelUtils.checkForPrintInStoreOptionsOverrides(
      this.props.store
    )?.printIconVisible;

    const locationAddress = getLocationAddress
      ? getLocationAddress(address)
      : [line1, line2, town, area, postcode].filter(Boolean).join(', ');

    return (
      <LocationItem
        isActive={isActive}
        ref={this.rootRef}
        onClick={this.onClick}
      >
        {WhiteLabelUtils.assetsForStoresEnabled && (
          <LocationIconContainer>
            {customerLogo && (
              // eslint-disable-next-line react/no-danger
              <div dangerouslySetInnerHTML={{ __html: customerLogo }} />
            )}
          </LocationIconContainer>
        )}

        <LocationInfo>
          <LocationTitle>{this.normalizedStoreName}</LocationTitle>
          <LocationAddress>{locationAddress}</LocationAddress>
        </LocationInfo>

        <LocationSubInfo>
          <LocationDistanceInfo>
            <ToolTipWrapper>
              {this.acceptsPaperless && showPrintIcon && (
                <ToolTipSmaller
                  placement={place}
                  title={this.renderTooltipTitle}
                >
                  <img src={printerDarkIcon} alt="Printer logo" />
                </ToolTipSmaller>
              )}
            </ToolTipWrapper>
            <LocationDistance>{distance}</LocationDistance>
          </LocationDistanceInfo>
          {openingHours ? (
            <LocationSeeMoreBtn onClick={this.seeMoreClickHandler}>
              {t('success:hoursBtn')}
            </LocationSeeMoreBtn>
          ) : null}
        </LocationSubInfo>
        {this.state.seeMoreBtn && openingHours ? (
          <LocationOpeningHours
            paddingLeft={WhiteLabelUi.pages.success.storeLogosEnabled}
          >
            {this.renderMoreStoreDetails(openingHours)}
          </LocationOpeningHours>
        ) : null}
      </LocationItem>
    );
  }
}

export default compose(
  inject('locationStore'),
  withWhitelabelProps({
    getLocationAddress: 'ui.pages.locate.getLocationAddress',
    specialDayTypes: 'ui.pages.locate.specialDayTypes',
  }),
  withTranslation(['constants', 'success', 'locate'])
)(StoreItem);

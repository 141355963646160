import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ModalBody, CancelTitle, ModalButton, ItemHolder } from './elements';
import { IMappedPurchaseOrderLine } from 'types/order';
import ItemContent from 'pages/details/components/ReturnItem/ItemContent';

interface Props extends WithTranslation {
  closeModal: () => void;
  onSubmit: () => void;
  product: IMappedPurchaseOrderLine;
}

const ReturnItemModal = ({
  product: { imageUrl, productName, price, size, priceCurrency, color },
  t,
  closeModal,
  onSubmit,
}: Props) => (
  <ItemHolder>
    <ItemContent
      imageUrl={imageUrl}
      description={productName}
      priceCurrency={priceCurrency}
      price={price}
      size={size}
      colour={color}
    />
    <ModalBody>
      <CancelTitle>{t('returnEnsure')}</CancelTitle>
      <ModalButton onClick={onSubmit}>{t('yesBtn')}</ModalButton>
      <ModalButton onClick={closeModal}>{t('noBtn')}</ModalButton>
    </ModalBody>
  </ItemHolder>
);

export default withTranslation('details')(ReturnItemModal);

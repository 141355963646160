import { RETURNS_PORTAL_BUILD_NAME } from '_common/constants/common';
import { EReturnType } from 'types/core';

export interface IUserInfo {
  phoneNumber: string | null;
  name: string | null;
  orderDate: Date | null;
}

export enum OrderStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
}

export interface IName {
  firstName: string;
  lastName: string;
}

export interface ICustomer {
  email: string;
  mobileNumber?: string;
  name: IName;
}

export interface IEventHistory {
  dateTime: Date;
  eventType: string;
}

export interface IDeliveryAddress {
  area: string;
  country: string;
  line1: string;
  line2: string;
  line3: string;
  postcode: string;
  town: string;
}

export interface IExternalOrderData {
  deliveryAddress: IDeliveryAddress;
  purchaseDate: string;
}

export interface IDestinationLocation {
  locationId: string;
  locationType: string;
}

export interface IPackage {
  labelValue: string;
}

export interface IReturnWindow {
  isWithinReturnWindow: boolean;
  lastDayOfReturn: string;
}

export interface IReturnCustomer {
  email: string;
  phoneNumber: string;
  name: string;
  address: IDeliveryAddress;
}

export interface ICreateReturnRequest {
  companyId: string;
  orderLines: Partial<IPurchaseOrderLine>[];
  externalOrderId: string;
  purchaseDate: string;
}

export interface ICreateReturnResponse {
  status: OrderStatus;
  caseNumber: string;
  caseId: string;
}

export interface IShipmentBooking {
  carrierBarcodeValue: string;
}

export interface IShippingLabel {
  pdfUrl: string;
}

export interface ReturnData {
  reasonComments?: string;
  returnReason?: string;
}

export interface IPurchaseReturnStatus {
  canBeReturned: boolean;
  hasBeenReturned?: boolean;
  cantBeReturnedReason?: string;
  isNotReturnable?: boolean;
  isPendingApproval?: boolean;
  isApproved?: boolean;
  isWithinReturnWindow?: boolean;
  lastDayOfReturn?: string;
}
export interface IPurchaseOrderLine {
  orderLineId: string;
  productName: string;
  price: number;
  priceCurrency?: string;
  imageUrl: string;
  quantity: number;
  size: string;
  color?: string;
  returnStatus: IPurchaseReturnStatus;
}

export interface IMappedPurchaseOrderLine extends IPurchaseOrderLine {
  productId: string;
  returnWindow: IPurchaseReturnStatus;
}

export interface IPurchaseOrderLineForOrderCreation extends IPurchaseOrderLine {
  reasonComments?: string;
  returnReason?: string;
  returnData?: ReturnData;
}

export interface IPurchase {
  orderId: string;
  externalOrderId: string;
  orderLines: IPurchaseOrderLineForOrderCreation[];
  purchaseDate: string;
  deliveryAddress: IDeliveryAddress;
  alwaysReturnable?: boolean;
  customer: ICustomer;
}

export interface IOrderCreationStatus {
  bookingId: string;
  recordExpirationTime: number;
  trackingId: string;
  pdfLabelUrl: string;
  orderId: string;
  status: string;
}

export interface ICreateOrderConfig {
  companyId: string;
  orderNumber?: string;
  appName: typeof RETURNS_PORTAL_BUILD_NAME;
  additionalInfo: string;
  externalOrderId?: string;
  from: any;
  returns: any;
  products?: any;
  consumerPaymentWasSuccessful?: boolean;
  payment?: any;
  printInStore?: boolean;
  returnType?: EReturnType;
  pickupDate?: string;
  pickupTime?: string;
  itemName?: string;
}

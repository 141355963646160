import React from 'react';
import { observer, inject } from 'mobx-react';

import StoreItem from '../StoreItem';
import { LocationsWrapper, LocationsItems } from './StoreListElements';
import { IStore } from 'types/store';
import LocationStore from '_common/stores/locationStore';

type Props = {
  locationStore?: LocationStore;
  onStoreClick?: (store: IStore) => void;
  currentTab?: 'map' | 'list';
};

@observer
class StoreList extends React.Component<Props> {
  renderLocationItem = (store: IStore) => {
    const {
      locationStore: { activeStoreId, assetsHub },
    } = this.props;
    const isActive = activeStoreId === store.storeId;
    const assets = assetsHub.get(store.companyId);

    return (
      <StoreItem
        key={store.storeId}
        currentTab={this.props.currentTab}
        isActive={isActive}
        store={store}
        onClick={this.props.onStoreClick}
        customerLogo={assets && assets.logo}
      />
    );
  };

  render() {
    const {
      locationStore: { stores },
    } = this.props;
    return (
      <LocationsWrapper>
        <LocationsItems>{stores.map(this.renderLocationItem)}</LocationsItems>
      </LocationsWrapper>
    );
  }
}

export default inject('locationStore')(StoreList);

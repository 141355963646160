import React, { Component } from 'react';
import { Card, Checkbox, OutlineButton } from '_common/components';
import { Trans, TransProps, withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { OrderStore, ThemeStore } from 'stores';
import {
  getErrorsOfAddressValidation,
  getMerchantFromUrl,
} from '_common/utils';
import { AsyncStatus } from '_common/constants/common';
import {
  CheckboxFormItemContainer,
  CheckboxWrapper,
  DetailsFormErrorMessage,
  LoadingMessage,
  Terms,
  ErrorMessage,
} from '../elements/elements';
import {
  CardActionButtons,
  StyledCardTitle,
} from '_common/components/Card/elements';

import { ConfirmButton, FormErrorMessage } from 'pages/start/elements';
import amplitude from '_common/utils/amplitude';
import DirectoryStore from '_common/stores/directoryStore';
import commonStoresActions from '_common/actions';
import {
  WhiteLabelConstants,
  withWhitelabelProps,
} from '_common/whitelabelConfig';
import { IDetailsStore } from 'types/internal';
import { EValidation_Errors, IRouteNavigator, IRouterMatch } from 'types/core';
import IntegratedDetails from './IntegratedDetails';
import NonIntegratedFields from './NonIntegratedFields';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';

type Props = RouteComponentProps<IRouterMatch> &
  TransProps & {
    detailsPageStore: IDetailsStore;
    orderStore: OrderStore;
    directoryStore: DirectoryStore;
    themeStore: ThemeStore;
    whiteLabeled: any;
    setCurrentGoBackMethod: (cb: Function) => void;
    routeNavigator: IRouteNavigator;
  };

interface State {
  isSubmitting: boolean;
  formHasEmptyFields: boolean;
}

@observer
class DetailsPage extends Component<Props, State> {
  static PAGE_NAME = 'Details page';

  formRef = React.createRef<FormInstance>();

  state = {
    isSubmitting: false,
    formHasEmptyFields: false,
  };

  componentDidMount() {
    this.props.setCurrentGoBackMethod(this.goBack);
    amplitude.logEventWithOrganisationAndUrl('page_open', {
      page_name: DetailsPage.PAGE_NAME,
    });

    /** extract company on root page */
    const company = getMerchantFromUrl();

    if (company) {
      const productJourney = commonStoresActions.getProductJourneyType();

      if (
        WhiteLabelConstants.IS_INTEGRATED_JOURNEY_AVAILABLE &&
        (productJourney.NOT_SUPPORTED || productJourney.INTEGRATED)
      ) {
        commonStoresActions.getOrderById(company).catch(() => {
          // of no order - go back to start page
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          productJourney.INTEGRATED && this.props.routeNavigator.previous();
        });
      }
    }
  }

  componentDidUpdate() {
    const {
      whiteLabeled: { emptyFormValidation, emptyFormCheck },
    } = this.props;
    if (!emptyFormValidation) return;

    if (this.state.formHasEmptyFields) {
      // check if all fields are populated
      if (
        Object.entries(this.formRef.current!.getFieldsValue()).every(
          entryArr => !emptyFormCheck(entryArr)
        )
      ) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ formHasEmptyFields: false });
      }
    }
  }

  onSubmit = async values => {
    if (this.state.isSubmitting) {
      return;
    }

    this.setState({ isSubmitting: true });

    const {
      detailsPageStore: { enableFormErrorMessage },
      whiteLabeled: { skipConfirmTerms },
      routeNavigator,
    } = this.props;

    this.logFieldEvent('click confirm', null);

    if (!skipConfirmTerms && !values.confirmTerms) {
      return;
    }

    try {
      enableFormErrorMessage(false);
      await Promise.all([
        commonStoresActions.validateAddress(values),
        commonStoresActions.validateShipment(),
      ]);
      routeNavigator.next();
    } catch (err) {
      if (err?.error_type === EValidation_Errors.INVALID_ADDRESS) {
        const addressErrors = getErrorsOfAddressValidation(values);
        amplitude.logValidationErrors(addressErrors, DetailsPage.PAGE_NAME);
        this.formRef.current.setFields(addressErrors);
      }
      if (err?.error_type === EValidation_Errors.INVALID_SHIPMENT) {
        enableFormErrorMessage(err.message);
      }
      this.setState({ isSubmitting: false });
    }
  };

  onFinishFailed = ({ values, errorFields }) => {
    const {
      whiteLabeled: { emptyFormValidation, emptyFormCheck },
    } = this.props;

    amplitude.logValidationErrors(errorFields, DetailsPage.PAGE_NAME);
    // check if any field is empty
    if (emptyFormValidation && Object.entries(values).some(emptyFormCheck)) {
      this.setState({ isSubmitting: false, formHasEmptyFields: true });
    } else {
      this.setState({ isSubmitting: false });
    }
  };

  onValuesChange = fields => {
    const keys = Object.keys(fields);
    if (!keys.length) {
      return;
    }
    const name = keys[0];
    const value = fields[name];

    if (this.formRef.current.isFieldsValidating([name])) {
      return;
    }

    this.props.detailsPageStore.setFormField(
      name,
      value,
      this.formRef.current.setFieldsValue
    );
  };

  /**
   * Back method, will always redirect to home page if a company is present
   */
  goBack = () => {
    this.props.routeNavigator.previous();
  };

  logFieldEvent = (eventName, fieldName, extraPayload?: object) => {
    const { href: url } = window.location;
    const { company: retailerName } = this.props.match.params;
    const payload: { [key: string]: string } = {
      url,
      retailerName,
      ...extraPayload,
    };
    if (fieldName) {
      payload.fieldName = fieldName;
    }
    amplitude.logEvent(eventName, payload);
  };

  handleInputClick = (fieldName: string) => () => {
    this.logFieldEvent('clicking on form fields', fieldName);
  };

  handleInputBlur = (fieldName: string) => {
    const { href: url } = window.location;
    const { company: retailerName } = this.props.match.params;

    if (!this.props.detailsPageStore.formFields[fieldName]) return;

    return () => {
      amplitude.logEvent('data has been entered into form field', {
        url,
        fieldName,
        retailerName,
      });
    };
  };

  handleSelect = (fieldId: string) => (value: string) => {
    const payload = {
      ...this.formRef.current.getFieldsValue([fieldId]),
      [fieldId]: value,
    };
    this.logFieldEvent('clicking an option', fieldId, payload);
  };

  handleCheckboxChange = () => {
    this.logFieldEvent('tick "I agree with the terms and conditions"', null);
  };

  shouldRenderReturnReasons = () => {
    const {
      directoryStore: { returnReasons },
    } = this.props;
    return (
      returnReasons &&
      returnReasons.enabled &&
      returnReasons.reasons &&
      returnReasons.reasons.length &&
      returnReasons.reasons.some(({ enabled }) => enabled)
    );
  };

  renderNonIntegratedDetails = () => {
    const {
      detailsPageStore: { formFields, showFormErrorMessage },
      whiteLabeled: {
        tcUrl,
        emptyFormValidation,
        skipConfirmTerms,
        showErrorTop,
      },
      t,
    } = this.props;

    return (
      <>
        <StyledCardTitle>{t('title')}</StyledCardTitle>
        {showErrorTop && showFormErrorMessage && (
          <ErrorMessage>{showFormErrorMessage}</ErrorMessage>
        )}
        <Form
          initialValues={{
            ...formFields,
            confirmTerms: false,
            purchaseDate: formFields.purchaseDate
              ? moment(formFields.purchaseDate)
              : formFields.purchaseDate,
          }}
          onFinish={this.onSubmit}
          onFinishFailed={this.onFinishFailed}
          onValuesChange={this.onValuesChange}
          ref={this.formRef}
          layout="vertical"
          requiredMark={false}
        >
          <NonIntegratedFields
            {...this.props}
            form={this.formRef.current}
            logFieldEvent={this.logFieldEvent}
            handleInputBlur={this.handleInputBlur}
          />
          {!skipConfirmTerms && (
            <CheckboxFormItemContainer>
              <Form.Item
                key="confirmTerms"
                name="confirmTerms"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    type: 'boolean',
                    transform: value => value || undefined,
                    message: t('confirmTermsError'),
                  },
                ]}
              >
                <CheckboxWrapper>
                  <Checkbox
                    fieldError={this.formRef.current?.getFieldError(
                      'confirmTerms'
                    )}
                    onChange={this.handleCheckboxChange}
                  />
                  <Terms>
                    <Trans
                      i18nKey="termsAndConditions"
                      ns="details"
                      components={{
                        1: (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={tcUrl}
                          >
                            terms and conditions
                          </a>
                        ),
                      }}
                    />
                  </Terms>
                </CheckboxWrapper>
              </Form.Item>
            </CheckboxFormItemContainer>
          )}
          <CardActionButtons>
            {emptyFormValidation && this.state.formHasEmptyFields && (
              <DetailsFormErrorMessage>
                {t('emptyFieldsError')}
              </DetailsFormErrorMessage>
            )}
            <ConfirmButton htmlType="submit">{t('nextBtn')}</ConfirmButton>
            <OutlineButton onClick={this.goBack}>
              {t('constants:backBtn')}
            </OutlineButton>
            <FormErrorMessage hidden={!showFormErrorMessage}>
              {showFormErrorMessage}
            </FormErrorMessage>
          </CardActionButtons>
        </Form>
      </>
    );
  };

  renderLeftSectionContent = () => {
    const {
      orderStore: { isIntegratedFlow, asyncStatus },
      directoryStore: { status },
    } = this.props;

    if (asyncStatus === AsyncStatus.LOADING || status === AsyncStatus.LOADING) {
      return <LoadingMessage>{this.props.t('loadingResults')}</LoadingMessage>;
    }
    return isIntegratedFlow ? (
      <IntegratedDetails
        {...this.props}
        pageName={DetailsPage.PAGE_NAME}
        handleInputClick={this.handleInputClick}
        handleInputBlur={this.handleInputBlur}
        logFieldEvent={this.logFieldEvent}
        backRedirectHandler={this.goBack}
      />
    ) : (
      this.renderNonIntegratedDetails()
    );
  };

  render() {
    return <Card>{this.renderLeftSectionContent()}</Card>;
  }
}

export default compose(
  inject('orderStore', 'detailsPageStore', 'directoryStore', 'themeStore'),
  withWhitelabelProps({
    lastNameText: 'ui.pages.details.textValues.lastNameLabel',
    lastNamePlaceholder: 'ui.pages.details.textValues.lastNamePlaceholder',
    firstNameText: 'ui.pages.details.textValues.firstNameLabel',
    firstNamePlaceholder: 'ui.pages.details.textValues.firstNamePlaceholder',
    kannaFirstNamePlaceholder:
      'ui.pages.details.textValues.kannaFirstNamePlaceholder',
    kannaLastNameText: 'ui.pages.details.textValues.kannaLastNameLabel',
    kannaLastNamePlaceholder:
      'ui.pages.details.textValues.kannaLastNamePlaceholder',
    purchaseDateLabel: 'ui.pages.details.textValues.purchaseDateLabel',
    purchaseDateRequiredErr:
      'ui.pages.details.textValues.purchaseDateRequiredErr',
    purchaseDatePlaceholder:
      'ui.pages.details.textValues.purchaseDatePlaceholder',
    returnReasonRequiredErr:
      'ui.pages.details.textValues.returnReasonRequiredErr',
    cityText: 'ui.pages.details.textValues.cityLabel',
    cityPlaceholder: 'ui.pages.details.textValues.cityPlaceholder',
    isZipVisible: 'ui.pages.details.textValues.isZipVisible',
    zipCodeText: 'ui.pages.details.textValues.zipText',
    zipCodeLength: 'ui.pages.details.textValues.zipLength',
    zipCodePlaceholder: 'ui.pages.details.textValues.zipPlaceholder',
    tcUrl: 'ui.pages.details.textValues.tcUrl',
    isAreaFreeText: 'ui.pages.details.textValues.isAreaFreeText',
    areaLabel: 'ui.pages.details.textValues.areaLabel',
    areaPlaceholder: 'ui.pages.details.textValues.areaPlaceholder',
    countryStates: 'ui.pages.details.textValues.countryStates',
    whitelabeledRules: 'ui.pages.details.rules',
    reasonCommentsRequiredText: 'ui.pages.details.reasonCommentsRequiredText',
    getStateLabel: 'ui.pages.details.getStateLabel',
    hasKanna: 'ui.pages.details.hasKanna',
    splitName: 'ui.pages.details.splitName',
    dateFormates: 'ui.pages.detail.dateFormates',
    handlingInstructions: 'ui.pages.details.handlingInstructions',
    hasItemName: 'ui.pages.details.hasItemName',
    address1Label: 'ui.pages.details.textValues.address1Label',
    address2Label: 'ui.pages.details.textValues.address2Label',
    address1Placeholder: 'ui.pages.details.textValues.address1Placeholder',
    address2Placeholder: 'ui.pages.details.textValues.address2Placeholder',
    orderedFiledNames: 'ui.pages.details.orderedFiledNames',
    emptyFormValidation: 'ui.pages.details.emptyFormValidation',
    emptyFormCheck: 'ui.pages.details.emptyFormCheck',
    skipConfirmTerms: 'ui.pages.details.skipConfirmTerms',
    showErrorTop: 'ui.pages.details.showErrorTop',
  }),
  withTranslation(['details', 'constants', 'wl'])
)(DetailsPage);

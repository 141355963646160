import { ICreateOrderConfig, IMappedPurchaseOrderLine } from 'types/order';
import {
  getShipmentConfig,
  removeUnsupportedPropsFromProduct,
} from '_common/utils';
import saveToStorage from './saveToStorage';
import validateSession from './validateSession';
import resetStoresWithUserData from './resetStoresWithUserData';
import isPaymentsRequiredForIntegrated from './isPaymentsRequiredForIntegrated';
import getProductJourneyType from './getProductJourneyType';
import amplitude from '../utils/amplitude';
import { IMobXStores } from 'types/internal';
import {
  ICommonStoresActions,
  IProductJourneyType,
  EAPP_LOCALES,
  EPrintOptions,
  TSPResult,
} from 'types/core';
import { IReturnFormFields } from 'types/company';
import { LatLng } from 'types/store';
import { IDetailsFormFields } from 'types/formFields';
import { ENABLED_FEATURES } from '_common/constants/common';
import { EWL_FEATURES } from 'types/features';

export class CommonActions implements ICommonStoresActions {
  stores: IMobXStores | null = null;

  registerStores = (stores: IMobXStores) => {
    /**
     * With that concept - 'this.stores' can be empty before register called.
     * Dont wrap in promise/catch - need to get an error */
    this.stores = stores;
  };

  loadTheme = () => this.stores.themeStore.makeThemeRequest();

  logout = () => this.stores.authStore.logout();

  refreshToken = () => this.stores.authStore.getNewAccessToken();

  getCompany = (companyName: string) =>
    this.stores.directoryStore.getCompany(companyName);

  generateShipmentBody = (): ICreateOrderConfig => {
    const {
      returnType,
      pickupDate,
      pickupTime,
    } = this.stores.returnMethodStore;
    const { companyConfig, returnReasons } = this.stores.directoryStore;
    const {
      isIntegratedFlow,
      products,
      userInfo,
      orderData,
    } = this.stores.orderStore;
    const { securePayData } = this.stores.whitelabelProxyStore;
    const isPaperless =
      this.stores.printOptionStore.selectedOption === EPrintOptions.PAPERLESS;
    const returnReason = returnReasons.reasons.find(
      reason =>
        reason.code === this.stores.detailsPageStore.formFields.returnReason
    )?.description;
    const populatedOrders = isIntegratedFlow
      ? products
          .map(
            this.stores.detailsPageStore.populateOrderWithReturnReasonComment
          )
          .map(removeUnsupportedPropsFromProduct)
          .filter(Boolean)
      : [];

    let config;
    try {
      config = getShipmentConfig({
        isIntegratedFlow,
        detailsPageStoreFormFields: {
          ...this.stores.detailsPageStore.formFields,
          returnReason,
          returnReasonCode: this.stores.detailsPageStore.formFields
            .returnReason,
        },
        startPageStoreFormFields: {
          ...this.stores.startPageStore.formFields,
        },
        companyConfig: { ...companyConfig },
        securePayData,
        intergratedUserProducts: populatedOrders,
        intergratedOrderInfo: { ...userInfo, orderId: orderData?.orderId },
        returnMethodData: { returnType, pickupDate, pickupTime },
        isPaperless,
      });
    } catch (e) {
      console.error('create shipment config error:', e);
    }
    return config;
  };

  createShipment = (company: string) => {
    const config = this.generateShipmentBody();
    return this.stores.whitelabelProxyStore.createShipment(config, company);
  };

  filterIntegratedOrderProducts = (
    products: IMappedPurchaseOrderLine[]
  ): IMappedPurchaseOrderLine[] =>
    products
      .map(this.stores.detailsPageStore.populateOrderWithReturnReasonComment)
      .filter(Boolean);

  getTrackingInfo = (trackingId: string, companyId: string) =>
    this.stores.trackingStore.getTrackingEvents(trackingId, companyId);

  saveToStorage = (storeName: string, model: object) =>
    saveToStorage(storeName, model);

  validateAddress = (detailsFormFields: IDetailsFormFields) =>
    this.stores.whitelabelProxyStore.validateAddress(detailsFormFields);

  validateShipment = async () => {
    const config = this.generateShipmentBody();
    if (!config) return Promise.reject();
    return this.stores.whitelabelProxyStore.validateShipment(config);
  };

  validateSession = (
    merchantId: string,
    resetAfterOrdersSubmit: boolean = false
  ) => validateSession(merchantId, resetAfterOrdersSubmit);

  getLocationTypes = () => this.stores.directoryStore.locationTypesOptions;

  resetStoresWithUserData = () => resetStoresWithUserData(this.stores);

  setConsumerPaymentWasSuccessful = (paymentDetails: TSPResult) =>
    this.stores.whitelabelProxyStore.setConsumerPaymentWasSuccessful(
      paymentDetails
    );

  setDetailsFormField = (field: string, value: string) =>
    this.stores.detailsPageStore.setFormField(field, value);

  saveDetailsFormToStorage = () =>
    this.stores.detailsPageStore.saveFormToStorage();

  getOrderById = (company: string): Promise<void> => {
    const { orderNumber, email } = this.stores.startPageStore.formFields;
    return this.stores.orderStore.getPurchaseByOrderId(
      orderNumber,
      company,
      email
    );
  };

  getJourneyType = (): string =>
    this.stores.orderStore.isIntegratedFlow
      ? 'Integrated journey'
      : 'Non-integrated journey';

  isPaymentsRequiredForIntegrated = (): boolean =>
    isPaymentsRequiredForIntegrated(this.stores);

  getProductJourneyType = (): IProductJourneyType =>
    getProductJourneyType(this.stores);

  getPurchaseWarrantyPeriod = (): number =>
    this.stores.directoryStore.purchaseWarrantyPeriodDays;

  getReturnFormFields = (): IReturnFormFields =>
    this.stores.directoryStore.returnFormFields;

  searchStoresByCoords = (
    config?: { lat?: number; lng?: number; company?: string },
    onDrag?,
    distance?
  ) => this.stores.locationStore.searchStoresByCoords(config, onDrag, distance);

  searchStoresByGeoLocation = (company?: string) =>
    this.stores.locationStore.searchStoresByGeoLocation(company);

  setActiveStoreId = (storeId: string) => {
    amplitude.logEventWithOrganisationAndUrl('selected store', { storeId });
    return this.stores.locationStore.setActiveStoreId(storeId);
  };

  setLocationType = (locationType: string) => {
    amplitude.logEventWithOrganisationAndUrl('selected location type filter', {
      locationType,
    });
    return this.stores.locationStore.setLocationType(locationType);
  };

  setSearchGeoCoordinates = (coords: LatLng) =>
    this.stores.locationStore.setSearchGeoCoordinates(coords);

  getPrintOption = () => this.stores.printOptionStore.selectedOption;

  setLanguage = (val: EAPP_LOCALES, isSilientSwitch: boolean = false) => {
    this.stores.themeStore.setCurrentLocale(val, isSilientSwitch);
  };

  // this is legacy way of feature resolving (using SSMs and integrated order check)
  validateFeatureAccess = (feature: EWL_FEATURES) => {
    if (!ENABLED_FEATURES.includes(feature)) {
      return false;
    }
    const feauteDetectConfig = {
      [EWL_FEATURES.NON_RETURNABLE_ITEMS]: () =>
        this.stores.directoryStore.productIntegratedJourney,
      [EWL_FEATURES.RETURN_APPROVALS]: () =>
        this.stores.directoryStore.productIntegratedJourney,
      [EWL_FEATURES.RETURN_WINDOWS]: () =>
        this.stores.directoryStore.productIntegratedJourney,
    };
    if (!feauteDetectConfig[feature]) {
      throw new Error(
        'Can`t perform feature check! Invalid feature config provided.'
      );
    }
    return feauteDetectConfig[feature]();
  };

  checkFeatureIsEnabled = (feature: EWL_FEATURES): boolean =>
    this.stores.featureStore.resolveFeatureStatus(feature);
}

export default new CommonActions();

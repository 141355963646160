import styled, { css } from 'styled-components';
import { StyledCardTitle } from '_common/components/Card/elements';
import { rtlMixin } from '_common/constants/theme';

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${props => props.theme.fontSize.l};
  font-weight: normal;
  color: black;
`;

export const Title = styled(StyledCardTitle)`
  margin: 0 0 10px;
`;

export const Subtitle = styled.strong`
  display: block;
  text-align: center;
  font-size: ${props => props.theme.fontSize.xs};
  padding: 1rem;
  border-top: solid 1px #dbdbdb;
  border-bottom: solid 1px #dbdbdb;
`;

export const StyledLink = styled.a`
  width: 30rem;
  background: black;
  color: ${props => props.theme.colors.white};
  border-radius: 3px;
  margin: 3rem auto 0;
  text-align: center;
  padding: 1rem 1.5rem;
`;

export const IconHolder = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #1b1;
  margin: 0 auto;
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const ItemHolder = styled.div`
  border-bottom: solid 1px #dbdbdb;
  display: flex;
  justify-content: space-between;
`;

export const Image = styled.div<{ imageUrl: string }>`
  width: 45px;
  height: 60px;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const ContentHolder = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${props => props.theme.fontSize.xs};
  width: 100%;
  padding: 1rem;
`;

export const Description = styled.div`
  font-weight: 600;
`;

export const Info = styled.div`
  text-align: right;
`;

export const CustomText = styled.p`
  ${rtlMixin(css`
    direction: rtl;
  `)}
`;

/* eslint camelcase: 0 */

import { action, observable, runInAction } from 'mobx';
import { localStore as storage, PDF_LABEL_URL } from 'storage';
import yamatoServices from '../services';
import {
  LABEL_POLLER_ATTEMPTS,
  LABEL_POLLER_INTERVAL,
} from '_common/constants/timeout';
import { ICommonStoresActions, TSPResult } from 'types/core';
import { ICreateOrderConfig } from 'types/order';
import { IStoreWithCommonActions } from 'types/internal';
import i18nService from '_common/services/i18nService';
import { ServerError } from '_common/errors';
/**
 * Observes state of creating labels via aus.post proxy.
 */
class YamatoProxyStore implements IStoreWithCommonActions {
  commonStoresActions: ICommonStoresActions;

  registerCommonActions = (commonStoresActions: ICommonStoresActions) => {
    this.commonStoresActions = commonStoresActions;
  };

  @observable
  pdfLabelURL = storage.get(PDF_LABEL_URL);

  @observable
  shipmentCreationResponse = null;

  @observable
  consumerPaymentWasSuccessful: boolean = false;

  @observable
  orderWasSubmitted: boolean = false;

  securePayData: TSPResult | null = null;

  @action
  resetStore = () => {
    this.shipmentCreationResponse = null;
    this.consumerPaymentWasSuccessful = false;
    this.orderWasSubmitted = false;
    this.securePayData = null;
  };

  @action
  createShipment = (config: ICreateOrderConfig, company): Promise<void> =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      let bookingId;
      try {
        // Shipment creation step.
        const shipmentCreationResponse = await yamatoServices.proxyService.createShipments(
          { ...config }
        );
        bookingId = shipmentCreationResponse.bookingId;
      } catch (e) {
        return reject(e);
      }
      // Multiple attempts to get shipment data.
      const poller = async (currentIndex): Promise<void> => {
        if (currentIndex >= LABEL_POLLER_ATTEMPTS) {
          return reject(new Error(i18nService.t('success:failedSubtitle')));
        }
        try {
          // Get shipment data.
          const detailedShipment = await yamatoServices.proxyService.getShipments(
            bookingId
          );
          // success: show data
          if (detailedShipment.status === 'COMPLETE - ORDER CREATED') {
            const { pdfLabelUrl } = detailedShipment;
            runInAction(() => {
              this.pdfLabelURL = pdfLabelUrl;
              this.shipmentCreationResponse = detailedShipment;
              this.orderWasSubmitted = true;
              storage.set(PDF_LABEL_URL, pdfLabelUrl);
            });
            company && this.commonStoresActions.validateSession(company, true);
            return resolve();
          }
          // failed on lodgements API step: show an error as it is
          if (detailedShipment.status && !detailedShipment.orderId) {
            return reject(
              new ServerError(
                detailedShipment.status,
                detailedShipment.errorType
              )
            );
          }
          // pending: next tick
          setTimeout(() => poller(currentIndex + 1), LABEL_POLLER_INTERVAL);
        } catch {
          /** In error case also re-trying. */
          setTimeout(() => poller(currentIndex + 1), LABEL_POLLER_INTERVAL);
        }
      };
      poller(0);
    });

  @action
  validateAddress = async (address): Promise<void> => {
    // address validation removed for yamato
    try {
      Object.keys(address).forEach(key => {
        this.commonStoresActions.setDetailsFormField(key, address[key]);
      });
      this.commonStoresActions.saveDetailsFormToStorage();
      return Promise.resolve();
    } catch (e) {
      return Promise.reject();
    }
  };

  // TODO add logic if any shipment validations will be needed for YAMATO
  @action
  validateShipment = async (): Promise<void> => {};

  @action
  setConsumerPaymentWasSuccessful = (paymentData: TSPResult) => {
    this.securePayData = paymentData;
    this.consumerPaymentWasSuccessful = false;
  };
}

export default YamatoProxyStore;

import styled from 'styled-components';

export const LocationsWrapper = styled.div`
  overflow-y: auto;
  max-height: 50vh;
  @media only screen and (max-width: 768px) {
    max-height: calc(100vh - 300px);
  }
  @media only screen and (max-width: 768px) and (orientation: landscape) {
    max-height: unset;
  }
`;

export const LocationsItems = styled.div`
  cursor: pointer;
`;

import starActive from './images/star-active.svg';
import starDisabled from './images/star-inactive.svg';
import './fonts.css';
import storeIcon from './images/3rdparty-pin.svg';
import storeActiveIcon from './images/3rdparty-pin-selected.svg';

const GoogleMapIcons = {
  storeIcon,
  storeActiveIcon,
  markerIcon: storeIcon,
  postOfficeIcon: storeIcon,
  postBoxIcon: storeIcon,
  lockerIcon: storeIcon,
  colocatedPostbox: storeIcon,
  activeMarkerIcon: storeActiveIcon,
  postOfficeActiveIcon: storeActiveIcon,
  postBoxActiveIcon: storeActiveIcon,
  lockerActiveIcon: storeActiveIcon,
  colocatedPostboxActive: storeActiveIcon,
};

export { starActive, starDisabled, GoogleMapIcons };

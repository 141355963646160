import { apiClient } from '_common/api/clients/clients';
import { IPurchase } from 'types/order';

import { ROOT_ORG_ID } from '_common/constants/common';

const getPurchase = async (
  externalOrderId: string,
  company: string,
  email: string
): Promise<IPurchase> => {
  const response = await apiClient.get(
    `/v1/returns-portal-service/${ROOT_ORG_ID}/get-purchase/${externalOrderId}/company/${company}/email/${email}`
  );
  return response.data;
};

export default {
  getPurchase,
};

import { action, computed, observable, runInAction } from 'mobx';
import { get, find } from 'lodash';
import i18nService from '_common/services/i18nService';
import { CURRENCY_SYMBOLS } from '_common/constants/common';
import OrdersService from '_common/services/ordersService';

import { IUserInfo, IPurchase, IMappedPurchaseOrderLine } from 'types/order';
import { EAsyncStatus, ICommonStoresActions } from 'types/core';
import { IStoreWithCommonActions } from 'types/internal';
import { WhiteLabelConstants } from '_common/whitelabelConfig';

class OrderStore implements IStoreWithCommonActions {
  @observable
  orderData: IPurchase | null = null;

  @observable
  orderNumber: string | null = null;

  @observable
  asyncStatus: EAsyncStatus = EAsyncStatus.IDLE;

  commonStoresActions: ICommonStoresActions;

  registerCommonActions = (commonStoresActions: ICommonStoresActions) => {
    this.commonStoresActions = commonStoresActions;
  };

  @computed
  get isIntegratedFlow(): boolean {
    return Boolean(this.orderData);
  }

  @computed
  get products(): IMappedPurchaseOrderLine[] {
    if (!this.orderData) return [];
    const { orderLines } = this.orderData;
    if (!orderLines || orderLines.length <= 0) {
      return [];
    }

    return orderLines.map(orderLine => ({
      ...orderLine,
      // here we map some new properties to old signature
      // like returnStatus -> returnWindow, orderLineId -> productId
      // so we don't have to change bindings in UI components
      productId: orderLine.orderLineId,
      returnWindow: orderLine.returnStatus,
      // priceCurrency might be missing in orderLine but we stil need to show it on UI for good UX
      // but omit sending a default currency to create-order on later step
      priceCurrency: get(
        CURRENCY_SYMBOLS,
        orderLine.priceCurrency || WhiteLabelConstants.DEFAULT_CURRENCY_NAME
      ),
      hasInitialPriceCurrency: !!orderLine.priceCurrency,
    }));
  }

  @computed
  get userInfo(): IUserInfo | object {
    if (!this.orderData) return {};
    const firstName = get(this.orderData, 'customer.name.firstName', '');
    const lastName = get(this.orderData, 'customer.name.lastName', '');
    const orderDate = get(this.orderData, 'purchaseDate', '');
    const fullName =
      firstName || lastName ? `${firstName} ${lastName}`.trim() : null;
    const addressFields = get(this.orderData, 'deliveryAddress', {});
    return {
      phoneNumber: get(this.orderData, 'customer.mobileNumber', null),
      orderDate,
      name: fullName,
      ...addressFields,
    };
  }

  @action
  getPurchaseByOrderId = async (orderNumber, company, email) => {
    if (!orderNumber) {
      this.orderData = null;
      return Promise.reject(Error(i18nService.t('stores:orderNumberError')));
    }

    /** For case success page -> details redirect after flow check. */
    if (orderNumber === this.orderNumber) {
      return Promise.resolve();
    }

    try {
      this.asyncStatus = EAsyncStatus.LOADING;
      this.orderNumber = orderNumber;
      const response = await OrdersService.getPurchase(
        orderNumber,
        company,
        email
      );

      runInAction(() => {
        this.orderData = response;
        this.asyncStatus = EAsyncStatus.SUCCESS;
      });

      return Promise.resolve();
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.asyncStatus = EAsyncStatus.FAILED;
        this.orderData = null;
        this.orderNumber = null;
      });
      return Promise.reject(Error(i18nService.t('stores:orderNotFoundError')));
    }
  };

  @action
  updateIntegratedComment = (value: string, orderLineId: string) => {
    const newOrderData = { ...this.orderData };
    const selectedOrderLine = find(
      newOrderData.orderLines,
      orderLine => orderLine.orderLineId === orderLineId
    );
    selectedOrderLine.reasonComments = value;

    this.orderData = newOrderData;
  };

  @action
  resetStore = () => {
    this.orderData = null;
    this.orderNumber = null;
  };
}

export default OrderStore;

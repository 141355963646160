import { get, includes } from 'lodash';

import { action, computed, observable, runInAction } from 'mobx';
import directoryService from '_common/services/directoryService';
import {
  LOCATION_LABEL_BY_TYPE,
  OPTION_ALL_LOCATIONS,
  OPTION_EXTENDED_HOURS,
  OPTION_PRINT_IN_STORE,
} from 'pages/locate/utils/locationUtils';
import { WhiteLabelConstants, WhiteLabelUtils } from '_common/whitelabelConfig';
import { EAsyncStatus, IOption } from 'types/core';

import {
  ICompany,
  ICustomerPayments,
  IReason,
  IReturnFormFields,
  IReturnReasons,
} from 'types/company';
import i18nService from '_common/services/i18nService';

class DirectoryStore {
  PRODUCT_NAME: string = WhiteLabelConstants.PRODUCT_NAME;

  DEFAULT_PAYMENT_TYPE = WhiteLabelConstants.DEFAULT_PAYMENT_TYPE;

  @observable
  companyConfig: ICompany;

  // TODO replace with actual property when implemented
  @observable
  customAdImage: string | null;

  @observable
  status: EAsyncStatus = EAsyncStatus.IDLE;

  @observable
  isURLDisabled: boolean = false;

  @computed
  get companyHasStoresFeed(): boolean {
    return !!this.companyConfig?.storesFeed?.length;
  }

  @computed
  get locationTypesOptions(): IOption[] {
    const options = [];

    const locationTypes = get(
      this.companyConfig,
      `products.${this.PRODUCT_NAME}.locationTypes`,
      []
    );
    locationTypes.forEach(location => {
      if (location.enabled) {
        options.push({
          value: location.type,
          get label() {
            return i18nService.t(LOCATION_LABEL_BY_TYPE[location.type]);
          },
        });
      }
    });
    if (options.length !== 1) {
      options.unshift(OPTION_ALL_LOCATIONS);
    }
    options.push(OPTION_EXTENDED_HOURS);

    if (
      !WhiteLabelUtils.checkForPrintInStoreOptionsOverrides()
        ?.printInStoreFilterVisible
    )
      return options;

    if (includes(window.location.search, 'printInStore=true')) {
      options.unshift(OPTION_PRINT_IN_STORE);
    } else {
      options.push(OPTION_PRINT_IN_STORE);
    }
    return options;
  }

  @computed
  get paymentConfig(): ICustomerPayments {
    return get(
      this.companyConfig,
      `products.${this.PRODUCT_NAME}.customerPayments`,
      {
        price: null,
        priceDescription: 'Price Description',
        paymentType: this.DEFAULT_PAYMENT_TYPE,
      }
    );
  }

  @computed
  get returnReasons(): IReturnReasons {
    return get(
      this.companyConfig,
      `products.${this.PRODUCT_NAME}.returnReasons`
    );
  }

  @computed
  get activeReturnReasons(): IReason[] {
    if (!this.returnReasons || !this.returnReasons.enabled) {
      return [];
    }
    return this.returnReasons.reasons.filter(reason => reason.enabled);
  }

  @computed
  get returnFormFields(): IReturnFormFields {
    return get(
      this.companyConfig,
      `products.${this.PRODUCT_NAME}.returnFormFields`
    );
  }

  @computed
  get rma(): string {
    return get(this.companyConfig, `products.${this.PRODUCT_NAME}.rma`);
  }

  @computed
  get website(): string {
    return get(this.companyConfig, 'website');
  }

  @computed
  get validationRegex(): string {
    return get(
      this.companyConfig,
      `products.${this.PRODUCT_NAME}.returnReasons.validationRegex`,
      false
    );
  }

  @computed
  get purchaseWarrantyPeriodDays(): number {
    return get(
      this.companyConfig,
      `products.${this.PRODUCT_NAME}.purchaseWarrantyPeriodDays`
    );
  }

  @computed
  get productIntegratedJourney(): boolean {
    return get(
      this.companyConfig,
      `products.${this.PRODUCT_NAME}.isIntegratedJourney`
    );
  }

  @computed
  get termsAndConditionsUrl(): string {
    return get(
      this.companyConfig,
      `products.${this.PRODUCT_NAME}.termsAndConditionsUrl`
    );
  }

  @computed
  get isReturnPaymentTextVisible(): boolean {
    return get(
      this.companyConfig,
      `products.${this.PRODUCT_NAME}.returnFormFields.returnPaymentText`,
      true
    );
  }

  @computed
  get orderIdCustomHeader(): string {
    return get(
      this.companyConfig,
      `products.${this.PRODUCT_NAME}.returnFormFields.orderIdCustomHeader`
    );
  }

  @computed
  get isApprovalRequired(): boolean {
    return get(
      this.companyConfig,
      `products.${this.PRODUCT_NAME}.isApprovalRequired`,
      false
    );
  }

  @action
  getCompany = async (companyName: string) => {
    this.status = EAsyncStatus.LOADING;
    try {
      const config = await directoryService.getCompany(companyName);
      const consumerUrlLive = get(
        config,
        `products.${this.PRODUCT_NAME}.consumerUrlLive`
      );
      runInAction('DirectoryStore::getCompany::success', () => {
        // for old companies this field will be missing so we have to check if it exists
        if (typeof consumerUrlLive === 'boolean' && !consumerUrlLive) {
          this.isURLDisabled = true;
        }
        this.companyConfig = config;
        this.status = EAsyncStatus.SUCCESS;
      });
    } catch (e) {
      console.error('DirectoryStore::getCompany', e);
      runInAction('DirectoryStore::getCompany::error', () => {
        this.status = EAsyncStatus.FAILED;
      });
    }
  };
}

export default DirectoryStore;

import { Col } from 'antd';
import styled from 'styled-components';
import { StyledCardTitle } from '_common/components/Card/elements';
import React from 'react';

export const LocateWrapper = styled.div`
  ${StyledCardTitle} {
    padding: 2.1rem 2rem;
    @media only screen and (max-width: 768px) {
      padding: 0 2rem 0 1.5rem;
      font-size: 2rem;
      height: 50px;
      display: flex;
      align-items: center;
    }
  }
`;

export const MobileTabs = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: flex;
    border-bottom: 1px solid rgba(151, 151, 151, 0.25);
  }
`;

export const TabHeader = styled.div<{ active: boolean }>`
  font-size: 14px;
  font-weight: bold;

  text-align: center;
  width: 50%;
  color: #231f20;
  margin-right: 0;
  padding: 16px;
  border: ${({ active }) =>
    active ? '1px solid #231f20' : '1px solid rgba(151, 151, 151, 0.25)'};
  cursor: pointer;
`;

export const TabWrapper = styled(({ isActive, ...rest }) => <Col {...rest} />)<{
  isActive?: boolean;
}>`
  display: block;
  && {
    @media only screen and (max-width: 768px) {
      position: relative;
      min-height: 340px;
      max-width: unset;
      flex: 1;
      display: ${({ isActive }) => `${isActive ? 'block' : 'none'} !important`};
      width: 100% !important;
    }
  }
`;

export const TabContent = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 768px) {
    display: ${({ active }) => (active ? 'block' : 'none')};
  }
`;

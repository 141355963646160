import { ALLOW_MULTIPLE_INTEGRATED_RETURNS } from '_common/constants/common';
import i18nService from '_common/services/i18nService';
import { EReturnType, LocationTypeAlias } from 'types/core';
import { getEnvVar } from '_common/utils/environmentVariable';

export const validateOnSpecialCharacters = (
  _,
  value: string,
  callback: (error?: Error) => void
) => {
  const error = new Error(i18nService.t('wl:validationSpecError'));
  if (/[<#&]/.test(value)) {
    return callback(error);
  }
  return callback();
};

export const validateOnSpecialCharactersAddress = (
  _,
  value: string,
  callback: (error?: Error) => void
) => {
  const error = new Error(i18nService.t('wl:validationSpecError'));
  if (/[<&]/.test(value)) {
    return callback(error);
  }
  return callback();
};

export const addSpecificFields = (
  config,
  { returnMethodData, handlingInstructions }
) => {
  if (returnMethodData?.returnType) {
    const { returnType, pickupDate, pickupTime } = returnMethodData;
    config.returnType = returnType;
    if (returnType === EReturnType.HOME_PICKUP) {
      config.pickupDate = pickupDate;
      config.pickupTime = pickupTime;
    }
  }
  if (handlingInstructions) {
    config.handlingInstructions = handlingInstructions;
  }
  return config;
};

export const convertFromAddress = ({
  phoneNumber,
  email,
  fullName,
  firstName,
  lastName,
  kannaLastName,
  kannaFirstName,
  addressLine1,
  addressLine2,
  city,
  state,
  postcode,
}) => ({
  email,
  line1: addressLine1,
  line2: addressLine2,
  town: city,
  ...(postcode && { postcode }),
  ...(phoneNumber && { phoneNumber }),
  ...(lastName && { lastName }),
  ...(firstName && { firstName }),
  ...(kannaFirstName &&
    kannaLastName && {
      kanna: { firstName: kannaFirstName, lastName: kannaLastName },
    }),
  ...(fullName && { name: fullName }),
  ...(state && { area: state }),
});

export interface IYamatoIntegrated {
  phoneNumber?: string;
  email?: string;
  name?: string;
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
}

export const convertReturns = ({
  purchaseDate,
  returnItems,
  returnReason,
  confirmTerms,
  reasonComments,
  returnsAuthorisationNumber,
  isIntegrated,
}) => ({
  purchaseDate,
  returnReason,
  confirmTerms,
  reasonComments,
  returnsAuthorisationNumber,
  ...(isIntegrated && {
    returnItems: `${returnItems}`,
  }),
});

const convertAddressForMap = ({ line1, line2, town, area }) => ({
  addressLine1: line1,
  addressLine2: line2,
  city: town,
  state: area,
});

const checkForReturnedProducts = !ALLOW_MULTIPLE_INTEGRATED_RETURNS;

const assetsForStoresEnabled = true;

const checkForPrintInStoreOptionsOverrides = () => ({
  printIconVisible: true,
  printInStoreFilterVisible: true,
});

/**
 * Returns Yamato-specific URLs, which can be used across multiple pages.
 * YL - is hardcoded Merchant, which will be created on each env for location search only.
 * @param postcode - postcode from details page, since Yamato not using Integrated mode.
 */
export const getUrlsConfig = ({ postcode }) => ({
  [LocationTypeAlias.PUDO]: {
    option_1: 'https://www.e-map.ne.jp/p/yamato01/',
    option_2: `${
      getEnvVar('REACT_APP_LOCATE_PAGE_DOMAIN') || window.location.origin
    }/YL/locate?postcode=${postcode}`,
  },
});

export default {
  addSpecificFields,
  convertFromAddress,
  convertAddressForMap,
  convertReturns,
  checkForReturnedProducts,
  assetsForStoresEnabled,
  checkForPrintInStoreOptionsOverrides,
};

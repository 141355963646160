import { Button } from '_common/components';
import styled, { css } from 'styled-components';
import { rtlMixin } from '_common/constants/theme';

export const Title = styled.h2<{
  integratedFlow?: boolean;
  centered?: boolean;
}>`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.xxl};
  font-weight: bold;
  ${({ centered }) => centered && 'text-align: center;'};
  & > a {
    text-decoration: underline;
    color: ${props => props.theme.colors.textAlt};
  }
  @media only screen and (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const SubTitle = styled.div<{ marginBottom?: number }>`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.l};
  color: ${props => props.theme.colors.textAlt};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px;`};
  & > p {
    text-decoration: underline;
  }
  @media only screen and (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

export const ReturnContent = styled.div`
  display: flex;
  min-height: 500px;
`;

export const ReturnStepsWrapper = styled.div<{ fullWidth: boolean }>`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: space-between;
  min-width: 0;
  & > * {
    padding: 1rem 3rem;
    @media only screen and (max-width: 768px) {
      padding: 2rem;
    }
    @media only screen and (max-width: 420px) {
      padding: 1rem 5rem 1rem 2rem;
      ${rtlMixin(css`
        padding: 1rem 1rem 1rem 5rem;
      `)}
    }
  }
  & > div:last-child {
    margin-bottom: 3rem;
    @media only screen and (max-width: 768px) {
      margin-bottom: 0;
      border-bottom: unset;
    }
  }
`;

export const IntegratedOrderDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IntegratedProductsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 2rem 0 13.5rem;
  @media only screen and (max-width: 768px) {
    padding: 2.5rem 1.25rem 0 14.375rem;
  }
  & > div:last-child {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`;
export const ProductWrapper = styled.div`
  display: flex;
  padding: 2rem;
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.colors.textAlt};
  & > div:last-child {
    margin: 0 0 0 auto;
    text-align: right;
    ${rtlMixin(css`
      margin: 0 auto 0 0;
      padding-left: 1rem;
    `)}
  }
`;

export const ProductImage = styled.div<{ imageUrl: string }>`
  width: 96px;
  min-width: 96px;
  height: 96px;
  min-height: 96px;
  margin-right: 2rem;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  @media only screen and (max-width: 768px) {
    margin-right: 0;
  }
`;

export const ProductDescription = styled.h3`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.m};
  letter-spacing: 0;
  font-weight: 600;
  @media only screen and (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

export const SuccessInfo = styled.div`
  display: flex;
  align-items: center;
  border-bottom: solid 1px #dbdbdb;
  margin-bottom: 1.5rem;
  padding: 3rem !important;
`;
export const SuccessIcon = styled.div<{ hidden?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 54px;
  height: 54px;
  margin-right: 2rem;
  ${({ hidden }) => hidden && 'visibility: hidden;'};
  ${rtlMixin(css`
    margin-right: initial;
    margin-left: 2rem;
  `)}
  @media only screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
  & img {
    width: 100%;
  }
`;

export const ReturnStep = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const StepWrapper = styled.div<{ column?: boolean }>`
  display: flex;
  align-items: ${({ column }) => (column ? 'flex-start' : 'center')};
  justify-content: space-between;
  padding-left: 1.5rem;
  width: 100%;
  ${({ column }) => column && 'flex-direction: column;'};
  margin-top: 0.9rem;
  @media only screen and (max-width: 768px) {
    padding-left: 0.625rem;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StepTitle = styled.h3<{ marginBottomMobile?: number }>`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.xl};
  letter-spacing: 0;
  font-weight: bold;
  margin: 0;
  @media only screen and (max-width: 768px) {
    ${({ marginBottomMobile }) =>
      marginBottomMobile && `margin-bottom: ${marginBottomMobile}px;`}
  }
`;

export const StepDetails = styled.div`
  font-weight: bold;
`;

export const StepNumber = styled.div`
  border: solid 2px #ddd;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.xxl};
  color: #222222;
  font-weight: 500;
  margin-right: 1.5rem;
  flex-shrink: 0;
  ${rtlMixin(css`
    margin-right: initial;
    margin-left: 1.5rem;
  `)}
  @media only screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

export const DownloadLabelButton = styled(Button)<{
  marginBottomMobile?: number;
}>`
  && {
    width: 220px;
    height: 36px;
    font-size: ${props => props.theme.fontSize.m};
    padding: 0.5rem 0;
    min-width: unset;
    margin-top: 2rem;
    @media only screen and (max-width: 1024px) {
      font-size: ${props => props.theme.fontSize.xl};
      ${({ marginBottomMobile }) =>
        marginBottomMobile && `margin-bottom: ${marginBottomMobile}px;`}
    }
    @media only screen and (max-width: 420px) {
      margin-top: initial;
      width: 30rem;
    }
  }
`;

export const HintText = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.s};
  letter-spacing: 0;
  color: ${props => props.theme.colors.textAlt};
  @media only screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
  & button {
    padding: 0;
    & > span {
      color: ${props => props.theme.colors.textAlt};
      text-decoration: underline;
    }
  }
  & button:hover {
    & > span {
      color: ${props => props.theme.colors.textAlt};
      text-decoration: none;
    }
  }
`;

export const LocationsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 1rem;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 420px) {
    margin-left: -4rem;
    width: calc(100% + 4rem);
  }
  & > div {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    @media only screen and (max-width: 420px) {
      width: 100%;
      border-bottom: 1px solid #dbdbdb;
      padding: 1.5rem 0;
    }
  }
  & > div:first-child {
    padding-right: 3rem;
    border-right: solid 1px #dbdbdb;
    ${rtlMixin(css`
      padding-right: initial;
      padding-left: 1.5rem;
      margin-left: 1.5rem;
      border-right: unset;
      border-left: solid 1px #dbdbdb;
    `)}
    @media only screen and (max-width: 1024px) {
      padding-right: 0;
      border-right: unset;
      ${rtlMixin(css`
        border-left: unset;
        border-right: unset;
        padding-left: 0;
      `)}
    }
  }
  & > div:last-child {
    padding-left: 3rem;
    @media only screen and (max-width: 1024px) {
      padding-left: 0;
    }
  }
`;
export const AddressWrapper = styled.div`
  display: flex;
  margin-bottom: 2rem;
  font-size: ${props => props.theme.fontSize.xs};
  color: ${props => props.theme.colors.textAlt};
  @media only screen and (max-width: 420px) {
    width: 100%;
    margin-bottom: 0;
  }
  & > div {
    display: flex;
    flex-direction: column;
  }
  & > div:first-child {
    @media only screen and (max-width: 420px) {
      flex-grow: 1;
      min-width: 80%;
    }
  }
  & > div:last-child {
    @media only screen and (max-width: 420px) {
      flex-grow: 0;
    }
  }
  span {
    font-family: ${props => props.theme.fonts.primary};
    font-size: ${props => props.theme.fontSize.xs};
    line-height: 1.2;
    @media only screen and (max-width: 768px) {
      font-size: 1.75rem;
    }
    @media only screen and (max-width: 420px) {
      font-size: 1.5rem;
    }
  }
`;
export const Distance = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1 1 auto;
  align-items: flex-end;
  @media only screen and (max-width: 768px) {
    flex-grow: 0;
  }
  @media only screen and (max-width: 420px) {
    font-size: 1.3rem;
  }
`;

export const ReturnFailedContent = styled.div`
  display: flex;
  height: 500px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 3rem;
  & > div {
    flex-direction: column;
    align-items: center;
    display: flex;
    & img {
      margin-bottom: 2rem;
    }
  }
`;

export const RetryButton = styled(DownloadLabelButton)`
  && {
    width: 335px;
    height: 60px;
    font-size: ${props => props.theme.fontSize.xl};
    @media only screen and (max-width: 768px) {
      width: 300px;
      height: 50px;
    }
  }
`;

export const NearestLocationText = styled.div`
  width: 100%;
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.xs};
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.colors.textAlt};

  margin-top: 2rem;
  padding: 5px;

  border-top: solid 1px #dbdbdb;
  border-bottom: solid 1px #dbdbdb;
  @media only screen and (max-width: 420px) {
    font-size: 1.5rem;
    margin-left: -4rem;
    width: calc(100% + 4rem);
  }
`;

export const FindLocationLink = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.m};
  font-weight: bold;
  color: ${props => props.theme.colors.textAlt};
  text-decoration: underline;
  cursor: pointer;

  margin-top: 2rem;
`;

export const StoreName = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSize.xs};
  color: ${props => props.theme.colors.textAlt};
  font-weight: bold;
  @media only screen and (max-width: 420px) {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;

export const LoaderArcWrapper = styled.div`
  width: 100%;

  min-height: 72rem;

  @media only screen and (max-width: 420px) {
    min-height: 50rem;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;

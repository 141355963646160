import React, { Component } from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import {
  IRouteNavigator,
  IRouterMatch,
  EReturnType,
  LocationTypeAlias,
} from 'types/core';
import { withTranslation, TransProps } from 'react-i18next';
import ReturnMethodStore from '../stores/returnMethodPageStore';
import { Card, OutlineButton } from '_common/components';
import {
  CardActionButtons,
  StyledCardTitle,
  StyledCardSubTitle,
  WarningText,
} from '_common/components/Card/elements';
import { ConfirmButton } from 'pages/start/elements';
import ReturnOption from './ReturnOption';
import DateTimeOption from './DateTimeOption';
import {
  generateReturnPickupDates,
  generateReturnPickupTimeslots,
} from '_common/utils/dates';
import { ButtonHolder } from '../elements';
import amplitude from '_common/utils/amplitude';
import commonStoresActions from '_common/actions';
import { MOBX_STORES } from 'storage';
import { IDetailsStore } from 'types/mobxStores';

type Props = RouteComponentProps<IRouterMatch> &
  TransProps & {
    returnMethodStore: ReturnMethodStore;
    setCurrentGoBackMethod: (cb?: Function) => void;
    routeNavigator: IRouteNavigator;
    detailsPageStore: IDetailsStore;
  };

@observer
class ReturnMethod extends Component<Props> {
  static PAGE_NAME = 'Select Return Method';

  componentDidMount() {
    const { setCurrentGoBackMethod } = this.props;

    setCurrentGoBackMethod(this.goBack);

    amplitude.logEventWithOrganisationAndUrl('page_open', {
      page_name: ReturnMethod.PAGE_NAME,
    });
  }

  goBack = () => this.props.routeNavigator.previous();

  handleConfirmClick = () => {
    const {
      returnMethodStore: { returnType, pickupDate, pickupTime },
    } = this.props;
    amplitude.logEventWithOrganisationAndUrl(
      'Return method is selected and Next button clicked'
    );

    commonStoresActions.saveToStorage(MOBX_STORES.ReturnMethodPageStore, {
      returnType,
      pickupDate,
      pickupTime,
    });

    this.props.routeNavigator.next();
  };

  onMethodSelect = (value: EReturnType) => {
    this.props.returnMethodStore.setReturnMethod(value);
    amplitude.logEventWithOrganisationAndUrl(`selected ${value} return method`);
  };

  onDateSelect = (value: string) => {
    this.props.returnMethodStore.setpickupDate(value);
    amplitude.logEventWithOrganisationAndUrl(
      `selected ${value} date for return`
    );
  };

  onTimeslotSelect = (value: string) => {
    this.props.returnMethodStore.setpickupTime(value);
    amplitude.logEventWithOrganisationAndUrl(
      `selected ${value} time for return`
    );
  };

  render() {
    const {
      t,
      returnMethodStore: { returnType, pickupDate, pickupTime },
      detailsPageStore: {
        formFields: { postcode },
      },
    } = this.props;
    const availableDates = generateReturnPickupDates();
    const availableTimeSlots = generateReturnPickupTimeslots(pickupDate);
    const showDateTimeButtons = returnType === EReturnType.HOME_PICKUP;
    const isSubmitDisabled =
      returnType === null ||
      (returnType === EReturnType.HOME_PICKUP &&
        (pickupTime === null || pickupDate === null));

    return (
      <Card>
        <StyledCardTitle>{t('title')}</StyledCardTitle>
        <StyledCardSubTitle>{t('subtitle')}</StyledCardSubTitle>
        <hr />
        <ReturnOption
          value={EReturnType.DROP_OFF}
          onSelect={this.onMethodSelect}
          isActive={returnType === EReturnType.DROP_OFF}
          translationName={LocationTypeAlias.PUDO}
          postcode={postcode}
          t={t}
        />
        <ReturnOption
          value={EReturnType.HOME_PICKUP}
          onSelect={this.onMethodSelect}
          isActive={returnType === EReturnType.HOME_PICKUP}
          translationName={LocationTypeAlias.COLLECT}
          t={t}
        />
        {showDateTimeButtons && (
          <>
            <ButtonHolder>
              {availableDates.map(date => (
                <DateTimeOption
                  key={date.toString()}
                  value={date.format('YYYY-MM-DD')}
                  isActive={pickupDate === date.format('YYYY-MM-DD')}
                  onSelect={this.onDateSelect}
                >
                  {date.format('M/D (ddd)')}
                </DateTimeOption>
              ))}
            </ButtonHolder>
            <ButtonHolder>
              {availableTimeSlots.map(slot => (
                <DateTimeOption
                  key={slot.value}
                  value={slot.value}
                  isActive={pickupTime === slot.value}
                  onSelect={this.onTimeslotSelect}
                  disabled={slot.disabled}
                >
                  {slot.label}
                </DateTimeOption>
              ))}
            </ButtonHolder>
          </>
        )}
        <WarningText>{t('warningText')}</WarningText>
        <CardActionButtons>
          <ConfirmButton
            htmlType="submit"
            onClick={this.handleConfirmClick}
            disabled={isSubmitDisabled}
          >
            {t('constants:submitBtn')}
          </ConfirmButton>
          <OutlineButton onClick={this.goBack}>
            {t('constants:backBtn')}
          </OutlineButton>
        </CardActionButtons>
      </Card>
    );
  }
}

export default compose(
  inject('returnMethodStore', 'detailsPageStore'),
  withTranslation(['returnMethod', 'wl', 'constants'])
)(ReturnMethod);

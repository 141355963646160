import {
  action,
  observable,
  runInAction,
  ObservableMap,
  ObservableSet,
  computed,
} from 'mobx';
import locationService from '_common/services/locationService';
import {
  getAllLocationsMapper,
  OPTION_ALL_LOCATIONS,
  LOCATIONS_MAPPER,
  OPTION_EXTENDED_HOURS,
  OPTION_PRINT_IN_STORE,
} from 'pages/locate/utils/locationUtils';
import { requestQueue } from '_common/utils';
import { AXIOS_CANCELLED } from '_common/constants/apiErrorResponces';
import {
  WhiteLabelUi,
  WhiteLabelUtils,
  WhiteLabelConstants,
} from '_common/whitelabelConfig';
import { LatLng, IStore, ICompanyAsset, LocationInfo } from 'types/store';
import { assetsClient } from '_common/api/clients/clients';
import { EPrintOptions } from 'types/core';
import commonStoresActions from '_common/actions';
import i18nService from '_common/services/i18nService';

const ADDRESS_NOT_FOUND = 'address not found';

class LocationStore {
  @observable
  mapCenterCoordinates = { ...WhiteLabelUi.common.defaultMapCenter };

  @observable
  previousSearchCoords: LatLng | null = null;

  @observable
  currentSearchCoords: LatLng | null = null;

  @observable
  stores: IStore[] = [];

  @observable
  activeStoreId: string | null = null;

  @observable
  locationType: string =
    commonStoresActions.getPrintOption() === EPrintOptions.PAPERLESS
      ? OPTION_PRINT_IN_STORE.value
      : OPTION_ALL_LOCATIONS.value;

  @observable
  isLoading: boolean = false;

  @observable
  showInfoPanel: boolean = false;

  @observable
  isNewLocationSearch: boolean = true;

  @observable
  mapDragActive: boolean = true;

  @observable
  assetsHub: ObservableMap<string, ICompanyAsset> = observable.map();

  @observable
  companiesWithNoAssets: ObservableSet<string> = observable.set();

  @action
  resetStore = () => {
    this.mapCenterCoordinates = { ...WhiteLabelUi.common.defaultMapCenter };
    this.previousSearchCoords = null;
    this.currentSearchCoords = null;
    this.stores = [];
    this.activeStoreId = null;
    this.locationType = OPTION_ALL_LOCATIONS.value;
    this.isLoading = false;
  };

  getCoordinates = async (request: google.maps.GeocoderRequest) => {
    try {
      return await locationService.getCoordinates(request);
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  getDistanceToLastSearch({ distance, unit }: LocationInfo) {
    if (this.previousSearchCoords) {
      return `${distance} ${unit}`;
    }
    return null;
  }

  unifyStoresFormat(stores: IStore[]): IStore[] {
    if (!stores.length) return [];
    const isNewFormat = !!stores[0].locationInfo && !!stores[0].store;
    if (isNewFormat) {
      return stores.map(({ locationInfo, store }) => ({
        ...store,
        locationInfo,
      }));
    }
    return stores;
  }

  reorderStoresByCPO(stores, cpoAmount = 2) {
    let cpoCount = 0;
    for (let i = 0; i < stores.length; i++) {
      if (cpoCount === cpoAmount) return;
      if (stores[i].locationType === 'POSTOFFICE') {
        stores.splice(cpoCount, 0, stores[i]);
        stores.splice(i + 1, 1);
        cpoCount++;
      }
    }
  }

  /**
   * Extract unique companies from the list of stores provided.
   * @param stores - list of stores
   * @returns list of companyIds
   */
  getUniqueCompanies(stores: IStore[]): string[] {
    return [
      ...new Set(
        stores
          .map(
            ({ companyId }): string =>
              // ignore companies with no assets or with fetched assets
              !this.companiesWithNoAssets.has(companyId) &&
              !this.assetsHub.has(companyId) &&
              companyId
          )
          .filter(Boolean)
      ),
    ];
  }

  /**
   * Fetches company's logo.
   * @async
   * @param company - company Id
   * @param logoUrl - logo image url
   */
  @action
  fetchLogoForCompany = async (company: string, logoUrl: string) => {
    try {
      const storedAssets = this.assetsHub.get(company);
      const logoResult = await assetsClient.get(logoUrl);

      runInAction(() => {
        this.assetsHub.set(company, {
          ...storedAssets,
          logo: logoResult.data,
        });
      });
    } catch (error) {
      console.info(`Error getting logo for company ${company}:`, error);
    }
  };

  /**
   * Fetches company application-config.json file for given stores.
   * Makes requests for company's logos
   * @async
   * @param stores - List of stores
   */
  @action
  getAssetsForStores = async (stores: IStore[]) => {
    const uniqueCompanyIds = this.getUniqueCompanies(stores);

    await Promise.all(
      uniqueCompanyIds.map(company =>
        assetsClient
          .get(
            `/${WhiteLabelConstants.PRODUCT_NAME}/${company}/application-config.json`
          )
          .then(({ data }) => {
            const { logo } = data.assets;
            if (!logo) {
              runInAction(() => {
                this.companiesWithNoAssets.add(company);
              });
            }
            logo && this.fetchLogoForCompany(company, logo);
          })
          .catch(error => {
            // if there's no assets for a company - don't try to fetch again on following requests
            if (error.response?.status === 404) {
              runInAction(() => {
                this.companiesWithNoAssets.add(company);
              });
            }
          })
      )
    );
  };

  @action
  async searchStoresByCoords(
    {
      lat = this.currentSearchCoords.lat,
      lng = this.currentSearchCoords.lng,
      company,
    }: { lat?: number; lng?: number; company?: string } = {
      lat: this.currentSearchCoords.lat,
      lng: this.currentSearchCoords.lng,
    },
    onDrag?: boolean,
    distance?: number
  ) {
    /**
     * when searching on map drag - do not reset stores list, only update it
     * and do not change client location
     */
    if (!onDrag) {
      this.stores = [];
      this.mapCenterCoordinates = { lat, lng };
    }

    this.isLoading = true;
    this.previousSearchCoords = { lat, lng };

    this.setActiveStoreId(null);

    const locationTypesMapper = getAllLocationsMapper();
    const { isPrinterOptionDisabled } = WhiteLabelUi.common;

    const channelConfig = {
      lat,
      lng,
      company,
      locationTypes:
        this.locationType === 'STREET_POST_BOXES'
          ? `${locationTypesMapper[this.locationType]},${
              LOCATIONS_MAPPER.EXPRESS_POSTBOX
            }`
          : locationTypesMapper[this.locationType],
      distance,
      extendedHours: this.locationType === OPTION_EXTENDED_HOURS.value,
      acceptsPaperless: isPrinterOptionDisabled
        ? null
        : this.locationType === OPTION_PRINT_IN_STORE.value,
    };

    const onError = e => {
      if (e === AXIOS_CANCELLED) {
        return e;
      }
      runInAction(() => (this.isLoading = false));
      console.error(e);
      return [];
    };
    const { cancelToken } = requestQueue.enqueueNewRequest([
      'locationStore',
      'searchStoresByCoords',
    ]);

    let stores:
      | IStore[]
      | typeof AXIOS_CANCELLED = await locationService
      .getGeoQueryStores(channelConfig, cancelToken)
      .catch(onError);

    if (stores === AXIOS_CANCELLED) return;

    stores = this.unifyStoresFormat(stores);

    if (stores && stores.length) {
      WhiteLabelUtils.assetsForStoresEnabled && this.getAssetsForStores(stores);
    }

    runInAction(() => {
      this.mapDragActive = onDrag;
      this.stores = stores as IStore[];
      this.isLoading = false;
    });
  }

  isBrowserSupportGeolocation(): boolean {
    return !!navigator.geolocation;
  }

  getUserGeoposition = (): Promise<LatLng> =>
    new Promise((resolve, reject) => {
      if (!this.isBrowserSupportGeolocation()) {
        reject(new Error(i18nService.t('success:browserError')));
      }

      const onSuccess = position => {
        const coords = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        return resolve(coords);
      };

      const onError = error => {
        runInAction(() => {
          /** show info panel */
          this.setInfoPanelVisibility(true);
        });
        return reject(error);
      };

      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    });

  @action
  searchStoresByGeoLocation = async (
    company?: string
  ): Promise<string | null> => {
    this.isLoading = true;

    const onError = e => {
      console.error(e);
      // eslint-disable-next-line react/no-this-in-sfc
      runInAction(() => (this.isLoading = false));
      return null;
    };

    try {
      const userGeolocation: LatLng = await this.getUserGeoposition();
      let result = ADDRESS_NOT_FOUND;

      if (!userGeolocation) return result;

      const results = await locationService
        .getReverseGeocode({
          ...userGeolocation,
        })
        .catch(onError);

      if (results && results.length) {
        result = results[0].formatted_address;
      }

      await this.searchStoresByCoords({ ...userGeolocation, company }).catch(
        onError
      );

      runInAction(() => {
        this.isLoading = false;
      });
      return result;
    } catch (e) {
      runInAction(() => (this.isLoading = false));
      return null;
    }
  };

  @action
  setSearchGeoCoordinates = (coords: LatLng) => {
    this.currentSearchCoords = coords;
  };

  @action
  setActiveStoreId = (storeId?: string) => {
    this.activeStoreId = storeId;
  };

  @action
  setLocationType = (locationType: string) => {
    this.locationType = locationType;
  };

  @action
  setInfoPanelVisibility = (showInfoPanel: boolean = false) => {
    this.showInfoPanel = showInfoPanel;
  };

  @computed
  get activeStoreData() {
    return this.stores.find(el => el.storeId === this.activeStoreId);
  }
}

export default LocationStore;

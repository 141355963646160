import { observer, inject } from 'mobx-react';
import { reaction } from 'mobx';
import React from 'react';
import { compose } from 'recompose';
import { Redirect, RouteComponentProps } from 'react-router';
import { Route, Switch, withRouter } from 'react-router-dom';
import { endsWith, isEmpty, includes } from 'lodash';
import { ThemeProvider } from 'styled-components';
import Modal from '_common/components/Modal/Modal';
import links from '_common/routes/urls';
import {
  Footer,
  Header,
  DependenciesLoader,
  PageStates,
  BackgroundWrapper,
  LoadingPage,
} from '_common/components';
import Details from 'pages/details/routes';
import Payment from 'pages/payment/routes';
import Start from 'pages/start/routes';
import Success from 'pages/success/routes';
import Tracking from 'pages/tracking/routes';
import PrintOption from 'pages/printOption/routes';
import ReturnMethod from 'pages/returnMethod/routes';
import ReturnSubmitted from 'pages/return-submitted/routes';
import Locate from 'pages/locate/routes';
import { getMerchantFromUrl } from '_common/utils';
import { DirectoryStore, ThemeStore, AuthStore, FeatureStore } from 'stores';
import commonStoresActions from '_common/actions';
import amplitude from '_common/utils/amplitude';
import {
  withWhitelabelProps,
  WhiteLabelServices,
} from '_common/whitelabelConfig';
import { Loader } from '@googlemaps/js-api-loader';
import {
  AsyncStatus,
  GOOGLE_API_KEY,
  DEFAULT_LOCALE,
} from '_common/constants/common';
import { IRouterMatch, IRouteNavigator } from 'types/core';
import NotFound from 'pages/not-found';
import { IReactionDisposer } from 'mobx/lib/internal';
import CustomResizeDetector from '_common/components/CustomResizeDetector';
import EmbedStore from '_common/stores/embedStore';
import { EWL_FEATURES } from 'types/features';

interface Props extends RouteComponentProps<IRouterMatch> {
  directoryStore: DirectoryStore;
  themeStore: ThemeStore;
  authStore: AuthStore;
  whiteLabeled: any;
  merchForRedirect?: string;
  routeNavigator: IRouteNavigator;
  embedStore: EmbedStore;
  featureStore: FeatureStore;
}

const isNotFoundPage = (location = window.location.pathname) =>
  endsWith(location, '/not-found');

const isSuccessPage = (location = window.location.pathname) =>
  endsWith(location, '/success');

const isLocatePage = (location = window.location.pathname) =>
  includes(location, '/locate');

interface State {
  goBack?: Function;
}

/** Google Maps JS API loader. Beta version is used to have promises instead of callbacks */
const loader = new Loader({
  apiKey: GOOGLE_API_KEY,
  version: 'beta',
  libraries: ['places', 'geometry'],
  language: DEFAULT_LOCALE,
});

@observer
class Main extends React.Component<Props, State> {
  disposeReaction: IReactionDisposer;

  state = {
    goBack: undefined,
  };

  componentDidMount(): void {
    const company = getMerchantFromUrl();
    if (company) {
      commonStoresActions.getCompany(company);
      commonStoresActions.validateSession(company);
      amplitude.initAmplitude(company);
    }
    this.disposeReaction = reaction(
      () => this.props.directoryStore.isURLDisabled,
      isURLDisabled => {
        if (isURLDisabled) {
          this.props.history.push('/not-found');
        }
      }
    );
  }

  componentWillUnmount() {
    this.disposeReaction();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      /** This is handles case when user go back using browser button, and some components in 'loaded/success' state */
      if (
        this.props.directoryStore.status === AsyncStatus.IDLE &&
        !this.props.directoryStore.companyConfig
      ) {
        window.location.reload();
      }
    }
  }

  registerGoBackAction = (callback?: Function) => {
    this.setState({ goBack: callback });
  };

  detectResize = (_, h: number) => {
    const { embedStore } = this.props;
    embedStore.setHeight('Main', h);
  };

  renderSwitch() {
    const {
      whiteLabeled: {
        footerWlProps,
        carrierLogo,
        returnMethod,
        availableLanguages,
        isTrackingPageDisabled,
      },
      directoryStore: { status, companyConfig, website },
      embedStore: { embedded: isEmbedded },
      merchForRedirect,
      routeNavigator,
      location: { pathname },
    } = this.props;
    const routes = [...Start, ...Details]
      .concat(
        commonStoresActions.checkFeatureIsEnabled(EWL_FEATURES.RETURN_APPROVALS)
          ? ReturnSubmitted
          : []
      )
      .concat([...Payment, ...Success])
      .concat(isTrackingPageDisabled ? [] : Tracking)
      .concat(returnMethod ? ReturnMethod : PrintOption)
      .concat([...Locate, ...WhiteLabelServices.getWhitelabelRoutes()]);
    const companyConfigProcessedAndValid =
      status !== AsyncStatus.LOADING && !isEmpty(companyConfig);

    return (
      <CustomResizeDetector
        isEmbedded={isEmbedded}
        handleHeight
        onResize={this.detectResize}
      >
        {isNotFoundPage || companyConfigProcessedAndValid ? (
          <div>
            {!isEmbedded && (
              <Header
                goBackAction={this.state.goBack}
                merchWebsite={website}
                configsAsyncStatus={status}
              />
            )}
            <BackgroundWrapper
              isEmbedded={isEmbedded}
              expandedFooter={footerWlProps.logoStacked}
              isFooterHidden={footerWlProps.isHidden}
            >
              <Switch>
                <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                <Route
                  exact
                  path={links.notFound}
                  render={(props: Props) => (
                    <NotFound {...props} merchForRedirect={merchForRedirect} />
                  )}
                />
                {status === AsyncStatus.LOADING ? (
                  <LoadingPage />
                ) : (
                  routes.map(route => (
                    <Route
                      exact
                      key={route.path}
                      path={route.path}
                      render={(props: Props) => (
                        <route.component
                          {...props}
                          setCurrentGoBackMethod={this.registerGoBackAction}
                          routeNavigator={routeNavigator}
                        />
                      )}
                    />
                  ))
                )}
                <Redirect to={links.notFound} />
              </Switch>
            </BackgroundWrapper>
            {!isEmbedded && !footerWlProps.isHidden && (
              <Footer
                {...footerWlProps}
                {...carrierLogo}
                isSuccessPage={isSuccessPage()}
                isLocatePage={isLocatePage()}
                availableLanguages={availableLanguages}
              />
            )}
          </div>
        ) : null}
      </CustomResizeDetector>
    );
  }

  render() {
    const { themeStore, authStore, featureStore } = this.props;
    return (
      <DependenciesLoader
        orderedDependenciesList={[loader.load.bind(loader)]}
        dependenciesList={[
          themeStore.makeThemeRequest,
          authStore.authoriseApplication,
          featureStore.getFeaturesForCompany,
        ]}
        loadingComponent={<PageStates.PageLoader global />}
        errorComponent={<PageStates.PageError />}
      >
        <ThemeProvider
          theme={{
            ...themeStore.theme,
            layoutDirection: themeStore.layoutDirection,
          }}
        >
          <Modal />
          {this.renderSwitch()}
        </ThemeProvider>
      </DependenciesLoader>
    );
  }
}

export default compose(
  withRouter,
  inject(
    'directoryStore',
    'themeStore',
    'authStore',
    'embedStore',
    'featureStore'
  ),
  withWhitelabelProps({
    footerWlProps: 'ui.common.footer',
    carrierLogo: 'ui.common.carrierLogo',
    returnMethod: 'ui.pages.returnMethod',
    availableLanguages: 'ui.common.availableLanguages',
    isTrackingPageDisabled: 'ui.pages.tracking.isDisabled',
  })
)(Main);

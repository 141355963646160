import React from 'react';
import { Tooltip } from 'antd';
import styled, { AnyStyledComponent, css } from 'styled-components';
import { rtlMixin } from '_common/constants/theme';

export const StyledTooltip: AnyStyledComponent = styled(props => (
  <Tooltip {...props} overlayClassName={props.className} />
))`
  & {
    position: relative;

    .ant-tooltip-inner {
      font-family: ${props => props.theme.fonts.primary};
      background-color: ${props => props.theme.colors.defaultBackground};
      color: #222;
      font-size: ${props => props.theme.fontSize.s};
      padding: 15px 20px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-width: 26rem;
      white-space: pre-wrap;
      word-break: break-word;
      & img {
        margin-right: 20px;
        ${rtlMixin(css`
          margin-right: initial;
          margin-left: 20px;
        `)};
      }
    }

    && .ant-tooltip-arrow {
      @media only screen and (max-width: 420px) {
        right: -198px;
      }
      & .ant-tooltip-arrow-content {
        background-color: white;
        width: 10px;
        height: 10px;
      }
    }
  }
`;

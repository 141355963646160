import * as React from 'react';
import {
  Icon,
  PrimaryText,
  SecondaryText,
  TextBlock,
  Tracker,
} from '../elements/tracker';
import { INTERNAL_EVENT_STATES } from '../stores/trackingStore';
import { TrackingEventWithInternalState } from 'types/tracking';
import { getSecondaryConfig } from '_common/utils/tracker';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

interface Props extends TrackingEventWithInternalState {
  index: number;
  list: TrackingEventWithInternalState[];
  t: TFunction;
}

const StatusTracker = ({
  customerDescription,
  utcDateTime,
  internalEventState,
  index,
  list,
  t,
}: Props) => {
  const { secondaryText, isTextShouldBeGreyed } = getSecondaryConfig(
    utcDateTime,
    internalEventState
  );
  const inactive =
    internalEventState === INTERNAL_EVENT_STATES.EVENT_NOT_OCCURRED;
  const nextEvent = list[index + 1];

  let isNextEventOccurred = false;
  if (
    nextEvent &&
    nextEvent.internalEventState !== INTERNAL_EVENT_STATES.EVENT_NOT_OCCURRED
  ) {
    isNextEventOccurred = true;
  }

  return (
    <Tracker inactive={inactive} isNextEventOccurred={isNextEventOccurred}>
      <Icon />
      <TextBlock>
        <PrimaryText inactive={inactive}>{t(customerDescription)}</PrimaryText>
        <SecondaryText isGreyed={isTextShouldBeGreyed}>
          {secondaryText}
        </SecondaryText>
      </TextBlock>
    </Tracker>
  );
};

export default withTranslation('wl')(StatusTracker);

import { ServerErrorType } from 'types/core';

class ServerError extends Error {
  errorType: ServerErrorType;

  constructor(message: string, errorType?: ServerErrorType) {
    super(message);
    this.name = this.constructor.name;
    this.errorType = errorType;
    this.stack = new Error().stack;
  }
}

export default ServerError;

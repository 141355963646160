import React, { Component } from 'react';
import { OutlineButton, TermsLink } from '_common/components';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { OrderStore } from 'stores';

import ReturnItem from './ReturnItem';
import {
  IntegratedButtonsBlock,
  OrderTitle,
  SubTitle,
} from '../elements/elements';
import commonStoresActions from '_common/actions';
import {
  CenteredItem,
  ConfirmButton,
  FormErrorMessage,
} from 'pages/start/elements';
import DirectoryStore from '_common/stores/directoryStore';
import { IDetailsStore } from 'types/internal';
import { IRouteNavigator, IRouterMatch } from 'types/core';
import { IPurchaseOrderLine, OrderStatus } from 'types/order';
import amplitude from '_common/utils/amplitude';
import { MOBX_STORES } from 'storage';
import { getErrorsOfAddressValidation, getItemReturnDate } from '_common/utils';
import prepareCreateReturnRequest from '_common/utils/prepareCreateReturnRequest';
import { CardActionButtons } from '_common/components/Card/elements';
import { EWL_FEATURES } from 'types/features';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';

type Props = RouteComponentProps<IRouterMatch> &
  WithTranslation &
  FormComponentProps & {
    detailsPageStore: IDetailsStore;
    orderStore: OrderStore;
    directoryStore: DirectoryStore;
    routeNavigator: IRouteNavigator;
    whiteLabeled: any;
    handleInputClick: (fieldName: string) => void;
    handleInputBlur: (fieldName: string) => void;
    logFieldEvent: (eventName, fieldName, extraPayload?: object) => void;
    pageName: string;
    backRedirectHandler: Function;
  };

interface State {
  touched: boolean;
}

enum EITEM_STATUSES {
  CANNOT_BE_RETURNED = 'CANNOT_BE_RETURNED',
  ALLOWED_FOR_RETURN = 'ALLOWED_FOR_RETURN',
  ALLOWED_AS_EXCEPTON = 'ALLOWED_AS_EXCEPTON',
}

interface IReturnItemValidationConfig {
  itemStatus: EITEM_STATUSES;
  errorDescription: string;
  returnNotAllowed: boolean;
}

@observer
class IntegratedDetails extends Component<Props, State> {
  formRef = React.createRef<FormInstance>();

  state = {
    touched: false,
  };

  goBack = () => this.props.backRedirectHandler();

  /**
   * Handle confirming a return. Ensures we don't allow continuing if there are
   * any errors present. Logs some data to amplitude as well if we get
   * errors or succeed
   */
  handleReturnConfirmation = async values => {
    const {
      match: {
        params: { company },
      },
      detailsPageStore: {
        enableFormErrorMessage,
        isAnyProductSelectedForReturn,
        selectedReturnReasonsForProduct,
        selectedReturnCommentForProduct,
      },
      directoryStore: { isApprovalRequired },
      orderStore: { orderData },
      whiteLabeled: { addressInvalidMsg },
      routeNavigator,
      logFieldEvent,
      pageName,
    } = this.props;
    const { detailsPageStore } = this.props;

    logFieldEvent('Book my return clicked', null);

    if (!isAnyProductSelectedForReturn) {
      this.setState({ touched: true });
      return;
    }
    try {
      enableFormErrorMessage(false);
      const req = prepareCreateReturnRequest(
        orderData,
        selectedReturnCommentForProduct,
        selectedReturnReasonsForProduct
      );
      if (
        // not all organizations have this function
        'createReturn' in detailsPageStore &&
        isApprovalRequired
      ) {
        const { caseNumber, status } = await detailsPageStore.createReturn(req);

        if (status === OrderStatus.PENDING_APPROVAL) {
          this.props.history.push(`/${company}/return-submitted`, {
            caseNumber,
            orderLines: req.orderLines,
          });
          commonStoresActions.validateSession(company, true);
          commonStoresActions.resetStoresWithUserData();
          return;
        }
      }

      // TODO: CHECK why there wasn't a values yes??
      commonStoresActions.saveToStorage(MOBX_STORES.DetailsPageStore, null);
      routeNavigator.next(
        `/${company}/${
          commonStoresActions.isPaymentsRequiredForIntegrated()
            ? 'payment'
            : 'success'
        }`
      );
    } catch {
      enableFormErrorMessage(addressInvalidMsg);
      amplitude.logValidationErrors(
        getErrorsOfAddressValidation(values),
        pageName
      );
      this.formRef.current.setFields(getErrorsOfAddressValidation(values));
    }
  };

  onFinishFailed = ({ errorFields }) => {
    amplitude.logValidationErrors(errorFields, this.props.pageName);
  };

  getStatusesForProduct = (product: IPurchaseOrderLine) => {
    const {
      orderStore: {
        orderData: { alwaysReturnable },
      },
      t,
    } = this.props;
    const {
      isPendingApproval,
      isWithinReturnWindow,
      hasBeenReturned,
      canBeReturned,
    } = product.returnStatus;

    const productIsReturnedStatuses = [].concat(
      commonStoresActions.checkFeatureIsEnabled(EWL_FEATURES.RETURN_APPROVALS)
        ? [
            {
              isInvalid: isPendingApproval,
              errorDescription: t('constants:PENDING_APPROVAL_PRODUCT_ERROR'),
            },
            {
              isInvalid: hasBeenReturned,
              errorDescription: t('constants:RETURNED_PRODUCT_ERROR'),
            },
          ]
        : [
            {
              isInvalid: hasBeenReturned,
              errorDescription: t('constants:RETURNED_PRODUCT_ERROR'),
            },
          ]
    );

    // if product has been returned already - don't check other statuses
    if (productIsReturnedStatuses.some(el => el.isInvalid))
      return productIsReturnedStatuses.filter(Boolean);

    // apply exception rules only if product is not returnable (for better UI messaging)
    return []
      .concat([
        {
          isInvalid:
            // this is a bit tricky as canBeReturned is false both for non-returnable item
            // and return-window rules - but we need to distinguish them to show apropriate message
            !canBeReturned &&
            (typeof isWithinReturnWindow !== 'boolean' || isWithinReturnWindow),
          errorDescription: t('constants:NON_RETURNABLE_PRODUCT_ERROR'),
          ...(!canBeReturned &&
            !hasBeenReturned && {
              isValidAsException: alwaysReturnable,
            }),
        },
        {
          isInvalid: !isWithinReturnWindow,
          errorDescription: t('constants:RETURN_WINDOW_ERROR'),
          ...(!isWithinReturnWindow &&
            !hasBeenReturned && {
              isValidAsException: alwaysReturnable,
            }),
        },
      ])
      .filter(Boolean);
  };

  validateProduct = (
    product: IPurchaseOrderLine
  ): IReturnItemValidationConfig => {
    const productStatuses = this.getStatusesForProduct(product);

    const validationResult = productStatuses.reduce(
      (result, productStatus) => {
        if (!productStatus.isInvalid) return result;
        if (productStatus.isValidAsException) {
          result.errorMessage = '';
          result.isValidAsException = true;
        } else if (!result.errorMessage) {
          result.errorMessage = productStatus.errorDescription;
        }
        return result;
      },
      { errorMessage: '', isValidAsException: false }
    );

    return {
      itemStatus: validationResult.isValidAsException
        ? EITEM_STATUSES.ALLOWED_AS_EXCEPTON
        : validationResult.errorMessage
        ? EITEM_STATUSES.CANNOT_BE_RETURNED
        : EITEM_STATUSES.ALLOWED_FOR_RETURN,
      errorDescription: validationResult.errorMessage,
      returnNotAllowed:
        !validationResult.isValidAsException && !!validationResult.errorMessage,
    };
  };

  getItemReturnStatus(product) {
    const { t } = this.props;

    const itemStatuses = {
      [EITEM_STATUSES.CANNOT_BE_RETURNED]: () =>
        t('integratedReturnNotReturnable'),
      [EITEM_STATUSES.ALLOWED_AS_EXCEPTON]: () =>
        t('integratedReturnException'),
      [EITEM_STATUSES.ALLOWED_FOR_RETURN]: () => {
        const returnDate = product.returnWindow?.lastDayOfReturn
          ? getItemReturnDate(product.returnWindow?.lastDayOfReturn)
          : null;
        return returnDate ? `${t('integratedReturnUntil')} ${returnDate}` : '';
      },
    };
    return itemStatuses[this.validateProduct(product).itemStatus]();
  }

  get groupedProducts() {
    const {
      orderStore: { products },
    } = this.props;

    const productsWithStatus = products.reduce((mappedArr, product) => {
      const resolvedStatus = this.getItemReturnStatus(product);
      const existingEl = mappedArr.find(
        el => el.returnStatus === resolvedStatus
      );
      if (existingEl) {
        existingEl.products.push(product);
      } else {
        mappedArr.push({
          returnStatus: resolvedStatus,
          products: [product],
          pickupDate:
            this.validateProduct(product).itemStatus ===
            EITEM_STATUSES.ALLOWED_FOR_RETURN
              ? product.returnWindow?.lastDayOfReturn
              : null,
        });
      }
      return mappedArr;
    }, []);
    productsWithStatus.sort((d1, d2) =>
      !d2.pickupDate
        ? -1
        : !d1.pickupDate
        ? 1
        : new Date(d2.pickupDate).getTime() - new Date(d1.pickupDate).getTime()
    );
    return productsWithStatus;
  }

  render() {
    const {
      directoryStore: { termsAndConditionsUrl, isApprovalRequired },
      detailsPageStore: { showFormErrorMessage, isAnyProductSelectedForReturn },
      t,
      handleInputClick,
      handleInputBlur,
    } = this.props;
    const { company: retailerName } = this.props.match.params;

    return (
      <Form
        onFinish={this.handleReturnConfirmation}
        onFinishFailed={this.onFinishFailed}
        ref={this.formRef}
        layout="vertical"
        requiredMark={false}
      >
        <OrderTitle>{t('integratedTitle')}</OrderTitle>
        {this.groupedProducts.map(group => (
          <React.Fragment key={group.returnStatus}>
            <SubTitle>{group.returnStatus}</SubTitle>
            {group.products.map(product => (
              <ReturnItem
                formRef={this.formRef}
                key={product.productId}
                product={product}
                onInputClick={handleInputClick}
                onInputBlur={handleInputBlur}
                returnNotAllowed={
                  this.validateProduct(product).returnNotAllowed
                }
                errorDescription={
                  this.validateProduct(product).errorDescription
                }
              />
            ))}
          </React.Fragment>
        ))}
        <IntegratedButtonsBlock>
          <CenteredItem>
            {this.state.touched && !isAnyProductSelectedForReturn ? (
              <FormErrorMessage>{t('selectItemError')}</FormErrorMessage>
            ) : (
              <FormErrorMessage hidden={!showFormErrorMessage}>
                {showFormErrorMessage}
              </FormErrorMessage>
            )}
            <CardActionButtons>
              <ConfirmButton htmlType="submit">
                {t(isApprovalRequired ? 'constants:submitBtn' : 'nextBtn')}
              </ConfirmButton>
              <OutlineButton onClick={this.goBack}>
                {t('constants:backBtn')}
              </OutlineButton>
            </CardActionButtons>
            {termsAndConditionsUrl && (
              <CenteredItem>
                <TermsLink
                  termsAndConditionsUrl={termsAndConditionsUrl}
                  retailerName={retailerName}
                />
              </CenteredItem>
            )}
          </CenteredItem>
        </IntegratedButtonsBlock>
      </Form>
    );
  }
}

export default compose(withTranslation(['details', 'constants']))(
  IntegratedDetails
);

import React from 'react';
import { yamatoLogo } from 'assets';
import {
  getUrlsConfig,
  validateOnSpecialCharacters,
} from '_common/whitelabelConfig/YAMATO/_utils';
import { TRACKING_EVENT_TYPES } from '_common/whitelabelConfig/YAMATO/_constants';
import {
  starActive,
  starDisabled,
  GoogleMapIcons,
} from '_common/whitelabelConfig/YAMATO/_assets';
import { IWhiteLabelUi } from 'types/whitelabelTypes';
import i18nService from '_common/services/i18nService';
import { EAPP_LOCALES, EDETAILS_ADDRESS_FIELD, EReturnType } from 'types/core';
import {
  address1ValidationRule,
  address2ValidationRule,
  cityValidationRule,
  kanaValidationRule,
  nameValidationRule,
  validateByPreconditions,
} from '_common/utils/validationUtils';
import { DEFAULT_SPECIAL_CHARS_REGEX } from '_common/constants/regex';

const markerImageSize = { width: 45, height: 45 };

const config: IWhiteLabelUi = {
  common: {
    carrierLogo: {
      src: yamatoLogo,
      alt: 'Easy returns by Yamato',
      dimensions: {
        desktop: {
          width: 54,
          height: 48,
        },
        mobile: {
          width: 54,
          height: 48,
        },
      },
    },
    countryCode: 'JP',
    availableLanguages: [EAPP_LOCALES.JA, EAPP_LOCALES.EN],
    isPrinterOptionDisabled: true,
    header: {
      showCarrierLogo: false,
      centeredLogo: true,
    },
    footer: {
      isHidden: true,
      showCarrierLogo: false,
      logoStacked: false,
      termsAndConditions: {
        href:
          'https://company-assets.apac-prod.doddle.tech/RETURNS_PORTAL/TERMS_AND_CONDITIONS_USPS/Terms+of+Use+-+Returns+Platform+(US).pdf',
        label: 'footerTermsLabel', // withTranslation
      },
      privacy: {
        href:
          'https://company-assets.apac-prod.doddle.tech/RETURNS_PORTAL/TERMS_AND_CONDITIONS_USPS/Privacy+Policy+and+Cookie+Notice+-+Returns+Platform+(US).pdf',
        label: 'footerPrivacyLabel', // withTranslation
      },
    },
    defaultMapCenter: {
      lat: 35.681894,
      lng: 139.767396,
    },
    isConsumerTextEnabled: (isIntegrated: boolean) => isIntegrated,
    fontsLocaleMapping: {
      [EAPP_LOCALES.EN]: {
        basic:
          'Helvetica Neue, Helvetica, Arial, Hiragino Sans, Hiragino Sans GB, Yu Gothic, sans-serif',
        primary:
          'Helvetica Neue, Helvetica, Arial, Hiragino Sans, Hiragino Sans GB, Yu Gothic, sans-serif',
      },
      [EAPP_LOCALES.JA]: {
        basic:
          'Helvetica Neue, Helvetica, Arial, Hiragino Sans, Hiragino Sans GB, Yu Gothic, sans-serif',
        primary:
          'Helvetica Neue, Helvetica, Arial, Hiragino Sans, Hiragino Sans GB, Yu Gothic, sans-serif',
      },
      [EAPP_LOCALES.AR]: {
        basic:
          'Helvetica Neue, Helvetica, Arial, Hiragino Sans, Hiragino Sans GB, Yu Gothic, sans-serif',
        primary:
          'DINNextLTArabic, Helvetica Neue, Helvetica, Arial, Hiragino Sans, Hiragino Sans GB, Yu Gothic, sans-serif',
      },
    },
  },
  pages: {
    returnMethod: {
      isVisible: true,
      getUrlsConfig,
    },
    start: {
      titleText: 'titleText', // withTranslation
      returnIsFreeText: 'startFree', // withTranslation
      ranPlaceholder: 'startPlaceholder', // withTranslation
      costText: 'startCost', // withTranslation
      confirmationCheckboxConfig: {
        getLabel: () => i18nService.t('start:returnsPolicyConfirmation'),
        getRequiredMessage: () =>
          i18nService.t('start:returnsPolicyConfirmationRequired'),
      },
      isValidationErrorsHidden: true,
      getOrderIdRegex: ({ prefix = '' }) => ({
        regexValue: DEFAULT_SPECIAL_CHARS_REGEX.regexValue,
        errorText: `${prefix} ${i18nService.t(
          'constants:DEFAULT_SPECIAL_CHARS_REGEX'
        )}`,
      }),
    },
    details: {
      orderedFiledNames: [
        [EDETAILS_ADDRESS_FIELD.ZIP],
        [EDETAILS_ADDRESS_FIELD.STATE, EDETAILS_ADDRESS_FIELD.CITY],
        [EDETAILS_ADDRESS_FIELD.ADDRESS1, EDETAILS_ADDRESS_FIELD.ADDRESS2],
        [EDETAILS_ADDRESS_FIELD.PHONE],
      ],
      dateFormates: ['YYYY-MM-DD', 'YYYY/MM/DD', 'YYYY.MM.DD', 'YYYY,MM,DD'],
      getStateLabel: item =>
        `${i18nService.language === EAPP_LOCALES.JA ? item.value : item.label}`,
      textValues: {
        countryStates: [
          // TODO: add translations
          { value: '北海道', label: 'Hokkaido' },
          { value: '青森県', label: 'Aomori Prefecture' },
          { value: '秋田県', label: 'Akita Prefecture' },
          { value: '岩手県', label: 'Iwate Prefecture' },
          { value: '宮城県', label: 'Miyagi Prefecture' },
          { value: '山形県', label: 'Yamagata Prefecture' },
          { value: '福島県', label: 'Fukushima Prefecture' },
          { value: '茨城県', label: 'Ibaraki Prefecture' },
          { value: '栃木県', label: 'Tochigi Prefecture' },
          { value: '群馬県', label: 'Gunma Prefecture' },
          { value: '埼玉県', label: 'Saitama Prefecture' },
          { value: '千葉県', label: 'Chiba Prefecture' },
          { value: '神奈川県', label: 'Kannagawa Prefecture' },
          { value: '東京都', label: 'Tokyo' },
          { value: '山梨県', label: 'Yamanashi Prefecture' },
          { value: '新潟県', label: 'Niigata Prefecture' },
          { value: '長野県', label: 'Nagano Prefecture' },
          { value: '富山県', label: 'Toyama Prefecture' },
          { value: '石川県', label: 'Ishikawa Prefecture' },
          { value: '福井県', label: 'Fukui Prefecture' },
          { value: '静岡県', label: 'Shizuoka Prefecture' },
          { value: '愛知県', label: 'Aichi Prefecture' },
          { value: '三重県', label: 'Mie Prefecture' },
          { value: '岐阜県', label: 'Gifu Prefecture' },
          { value: '大阪府', label: 'Osaka' },
          { value: '京都府', label: 'Kyoto' },
          { value: '滋賀県', label: 'Shiga Prefecture' },
          { value: '奈良県', label: 'Nara Prefecture' },
          { value: '和歌山県', label: 'Wakayama Prefecture' },
          { value: '兵庫県', label: 'Hyogo Prefecture' },
          { value: '岡山県', label: 'Okayama Prefecture' },
          { value: '広島県', label: 'Hiroshima Prefecture' },
          { value: '山口県', label: 'Yamaguchi Prefecture' },
          { value: '鳥取県', label: 'Tottori Prefecture' },
          { value: '島根県', label: 'Shimane Prefecture' },
          { value: '香川県', label: 'Kagawa Prefecture' },
          { value: '徳島県', label: 'Tokushima Prefecture' },
          { value: '愛媛県', label: 'Ehime Prefecture' },
          { value: '高知県', label: 'Kochi Prefecture' },
          { value: '福岡県', label: 'Fukuoka Prefecture' },
          { value: '佐賀県', label: 'Saga Prefecture' },
          { value: '長崎県', label: 'Nagasaki Prefecture' },
          { value: '熊本県', label: 'Kumamoto Prefecture' },
          { value: '大分県', label: 'Oita Prefecture' },
          { value: '宮崎県', label: 'Miyazaki Prefecture' },
          { value: '鹿児島県', label: 'Kagoshima Prefecture' },
          { value: '沖縄県', label: 'Okinawa Prefecture' },
        ],
        firstNameLabel: 'firstNameLabel', // withTranslation
        firstNamePlaceholder: 'firstNamePlaceholder', // withTranslation
        lastNameLabel: 'lastNameLabel', // withTranslation
        lastNamePlaceholder: 'lastNamePlaceholder', // withTranslation
        kannaFirstNamePlaceholder: 'kannaFirstNamePlaceholder', // withTranslation
        kannaLastNameLabel: 'kannaLastNameLabel', // withTranslation
        kannaLastNamePlaceholder: 'kannaLastNamePlaceholder', // withTranslation
        purchaseDateLabel: 'detailsPurchaseDate', // withTranslation
        purchaseDatePlaceholder: 'detailsPurchaseDatePlaceholder', // withTranslation
        purchaseDateRequiredErr: 'detailsPurchaseDateRequiredErr', // withTranslation
        returnReasonRequiredErr: 'detailsReturnReasonRequiredErr', // withTranslation
        cityLabel: 'detailsCity', // withTranslation
        cityPlaceholder: 'detailsCityPlaceholder', // withTranslation
        areaLabel: 'detailsArea', // withTranslation
        areaPlaceholder: 'detailsAreaPlaceholder', // withTranslation
        isZipVisible: true,
        zipText: 'detailsZip', // withTranslation
        address1Label: 'address1Label', // withTranslation
        address2Label: 'address2Label', // withTranslation
        address1Placeholder: 'address1Placeholder', // withTranslation
        address2Placeholder: 'address2Placeholder', // withTranslation
        zipLength: 7,
        zipPlaceholder: 'detailsZipPlaceholder', // withTranslation
        zipPattern: /^\d+$/,
        zipError: 'detailsZipError', // withTranslation
        tcUrl:
          'https://company-assets.apac-prod.doddle.tech/RETURNS_PORTAL/TERMS_AND_CONDITIONS_USPS/Terms+of+Use+-+Returns+Platform+(US).pdf',
        isAreaFreeText: false,
        addressInvalidMsg: i18nService.t('wl:detailsAdressError'), // withTranslation
      },
      rules: {
        lastName: [
          {
            whitespace: true,
            required: true,
            message: i18nService.t('wl:lastNameError.required'), // withTranslation
          },
          {
            max: 15,
            message: <p>{i18nService.t('wl:lastNameError.length')}</p>, // withTranslation
          },
          nameValidationRule(
            <p>{i18nService.t('wl:lastNameError.invalid')}</p>
          ),
        ],
        firstName: [
          {
            whitespace: true,
            required: true,
            message: i18nService.t('wl:firstNameError.required'), // withTranslation
          },
          {
            max: 15,
            message: <p>{i18nService.t('wl:firstNameError.length')}</p>, // withTranslation
          },
          nameValidationRule(
            <p>{i18nService.t('wl:firstNameError.invalid')}</p>
          ),
        ],
        kannaFirstName: [
          {
            required: true,
            whitespace: true,
            message: i18nService.t('wl:kannaFirstNameError.required'), // withTranslation
          },
          {
            max: 30,
            message: <p>{i18nService.t('wl:kannaFirstNameError.length')}</p>, // withTranslation
          },
          kanaValidationRule(
            <p>{i18nService.t('wl:kannaFirstNameError.invalid')}</p>
          ),
        ],
        kannaLastName: [
          {
            required: true,
            whitespace: true,
            message: i18nService.t('wl:kannaLastNameError.required'), // withTranslation
          },
          {
            max: 30,
            message: <p>{i18nService.t('wl:kannaLastNameError.length')}</p>, // withTranslation
          },
          kanaValidationRule(
            <p>{i18nService.t('wl:kannaLastNameError.invalid')}</p>
          ),
        ],
        zipCode: [
          {
            required: true,
            whitespace: true,
            message: i18nService.t('wl:zipCodeError.required'), // withTranslation
          },
          {
            min: 7,
            message: <p>{i18nService.t('wl:zipCodeError.length')}</p>, // withTranslation
          },
          {
            validator: validateByPreconditions({
              regex: {
                value: /^\d+$/,
                message: <p>{i18nService.t('wl:zipCodeError.invalid')}</p>,
              },
            }),
          },
        ],
        fullName: [
          {
            required: true,
            whitespace: true,
            message: i18nService.t('wl:detailsFullnameReq'), // withTranslation
          },
          {
            max: 32,
            message: i18nService.t('wl:detailsFullnameErrAlt'), // withTranslation
          },
          {
            validator: (
              rule,
              value: string,
              callback: (error?: Error) => void
            ) => {
              validateOnSpecialCharacters(rule, value, callback);
            },
          },
        ],
        addressLine1: [
          {
            required: true,
            whitespace: true,
            message: i18nService.t('wl:addressLine1Error.required'), // withTranslation
          },
          {
            max: 15,
            message: <p>{i18nService.t('wl:addressLine1Error.length')}</p>, // withTranslation
          },
          address1ValidationRule(
            <p>{i18nService.t('wl:addressLine1Error.invalid')}</p>
          ),
        ],
        addressLine2: [
          {
            max: 30,
            message: <p>{i18nService.t('wl:addressLine2Error.length')}</p>, // withTranslation
          },
          address2ValidationRule(
            <p>{i18nService.t('wl:addressLine2Error.invalid')}</p>
          ),
        ],
        area: [
          {
            required: true,
            whitespace: true,
            message: i18nService.t('wl:detailsAreaReq'), // withTranslation
          },
        ],
        city: [
          {
            required: true,
            whitespace: true,
            message: i18nService.t('wl:detailsCityError.required'), // withTranslation
          },
          {
            max: 70,
            message: <p>{i18nService.t('wl:detailsCityError.length')}</p>, // withTranslation
          },
          cityValidationRule(
            <p>{i18nService.t('wl:detailsCityError.invalid')}</p>
          ),
        ],
        phoneNumber: [
          {
            required: true,
            whitespace: true,
            message: i18nService.t('details:mobileError'),
          },
          {
            min: 10,
            message: <p>{i18nService.t('wl:mobileError.length')}</p>, // withTranslation
          },
          {
            max: 11,
            message: <p>{i18nService.t('wl:mobileError.length')}</p>, // withTranslation
          },
          {
            validator: validateByPreconditions({
              regex: {
                value: /^\d+$/,
                message: <p>{i18nService.t('wl:mobileError.invalid')}</p>,
              },
            }),
          },
        ],
      },
      noReasonsText: null,
      handlingInstructions: [
        { label: '', value: null },
        { label: i18nService.t('wl:instruction1'), value: '01' },
        { label: i18nService.t('wl:instruction2'), value: '02' },
        { label: i18nService.t('wl:instruction3'), value: '03' },
        { label: i18nService.t('wl:instruction4'), value: '04' },
        { label: i18nService.t('wl:instruction5'), value: '05' },
      ],
      splitName: true,
      hasKanna: true,
      hasItemName: true,
      noActiveReasonsAllowed: true,
      integratedReasonCommentsEnabled: true,
      reasonCommentsRequiredText: 'detailsCommentError', // withTranslation
      emptyFormValidation: true,
      emptyFormCheck: ([key, val]) => {
        if (key !== 'addressLine2' && key !== 'handlingInstructions') {
          return !val;
        }
        return false;
      },
      wrapReasonCodeText: true,
      skipConfirmTerms: true,
      showErrorTop: true,
    },
    tracking: {
      isDisabled: true,
      trackersList: [
        {
          type: TRACKING_EVENT_TYPES.ORDER_RETURN_CREATED,
          descriptionKey: 'wl:trackingCreated',
        },
        {
          type: TRACKING_EVENT_TYPES.PARCEL_REACHED_STORE,
          descriptionKey: 'wl:trackingReached',
        },
        {
          type: TRACKING_EVENT_TYPES.CARRIER_COLLECTED,
          descriptionKey: 'wl:trackingCollected',
        },
        {
          type: TRACKING_EVENT_TYPES.BACK_AT_WAREHOUSE,
          descriptionKey: 'wl:trackingBack',
        },
      ],
    },
    success: {
      textValues: {
        successSearchPlaceholder: 'successPlaceholder', // withTranslation
      },
      distanceUnit: 'km',
      storeLogosEnabled: false,
      availableTypeOptions: ['PRINT_IN_STORE', 'ALL_LOCATIONS'],
      returnSubtitlePaperless: 'wl:returnSubtitlePaperless',
      returnSubtitleOwnLabel: successType =>
        successType === EReturnType.DROP_OFF
          ? 'wl:returnSubtitleDropOff'
          : 'wl:returnSubtitleOwnLabel',
      pdfDownloadEnabled: true,
      getUrlsConfig,
      isMobileMarketingUrlVisible: true,
      style: {
        OTHER_PLACES: {
          fontWeight: 'normal',
          fontSize: '1.5rem',
        },
      },
    },
    ratings: {
      config: {
        title: 'ratingsTitle', // withTranslation
        subTitle: 'ratingsSubtitle', // withTranslation
        icons: {
          starActive,
          starDisabled,
        },
      },
    },
    locate: {
      shouldGetCoordinatesFromNavigator: true,
      isLocationsTypeSelectDisabled: true,
      GoogleMapIcons,
      defaultUserAddress: '銀座',
      defaultSearchDistance: 5,
      defaultSearchServices: 'RETURNS,COLLECTIONS',
      getLocationAddress: ({ postcode, line1, line2, area, town }) =>
        [postcode && `〒${postcode}, `, area, town, line1, line2]
          .filter(Boolean)
          .join(''),
      markerImageSize,
      specialDayTypes: [{ fullName: 'holidays', shortName: 'holiday' }],
      mapBoundsDelta: 0.001,
      getDefaultMarkerOptions: google => ({
        anchor: new google.maps.Point(
          markerImageSize.width / 2,
          markerImageSize.height / 2
        ), // bind to center of pin
      }),
    },
  },
};

export default config;

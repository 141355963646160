import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Trans, TransProps, withTranslation } from 'react-i18next';
import { CustomTextLanguageConfig, IRouterMatch, StepConfig } from 'types/core';
import { IPurchaseOrderLine } from 'types/order';
import { Card } from '_common/components';
import { compose } from 'recompose';
import {
  CardContent,
  Title,
  Subtitle,
  StyledLink,
  IconHolder,
  CustomText,
} from './elements';
import { CheckmarkIconLarge } from '_common/icons';
import Product from './Product';
import { inject } from 'mobx-react';
import { find } from 'lodash';
import ThemeStore from '_common/stores/themeStore';

// props from redirected page
type StateType = {
  website: string;
  caseNumber: string;
  orderLines: IPurchaseOrderLine[];
};

type Props = RouteComponentProps<IRouterMatch, {}, StateType> &
  TransProps<any> & {
    themeStore: ThemeStore;
  };

const ReturnSubmittedPage = ({
  location: { state },
  t,
  history,
  themeStore,
  i18n,
}: Props) => {
  useEffect(() => {
    if (!state.caseNumber || !state.orderLines) {
      history.push('not-found');
    }
  }, [state, history]);

  const customLanguageConfig: CustomTextLanguageConfig = find(
    themeStore.theme.config.consumerText.languages,
    (lang: CustomTextLanguageConfig) => lang.language === i18n.language
  );
  const customReturnSubmitConfig = find(
    customLanguageConfig?.steps,
    (step: StepConfig) => step.id === 6
  );
  const customWebsiteAddress =
    customReturnSubmitConfig?.texts?.primaryButtonURL;

  return (
    <Card>
      <CardContent>
        <IconHolder>
          <CheckmarkIconLarge />
        </IconHolder>
        <Title>{t('title')}</Title>
        <CustomText>
          <Trans
            i18nKey="text"
            ns="returnSubmitted"
            values={{ caseNumber: state.caseNumber }}
            components={{
              1: <strong />,
            }}
          />
        </CustomText>
        <Subtitle>{t('subtitle')}</Subtitle>
        {state.orderLines.map(orderLine => (
          <Product orderLine={orderLine} key={orderLine.orderLineId} />
        ))}
        <StyledLink href={customWebsiteAddress || state.website || '#'}>
          {t('btnText')}
        </StyledLink>
      </CardContent>
    </Card>
  );
};

export default compose(
  inject('themeStore'),
  withTranslation('returnSubmitted')
)(ReturnSubmittedPage);

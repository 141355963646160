import * as qs from 'qs';
import {
  RETURNS_PORTAL_SERVICE_POSTFIX,
  ROOT_ORG_ID,
} from '_common/constants/common';
import { IProxyService } from 'types/whitelabelTypes';

const proxyService: IProxyService = {
  createReturn: async function createReturn(body) {
    const res = await this.apiClient.post(
      `/v1/returns-portal-service${RETURNS_PORTAL_SERVICE_POSTFIX}/${ROOT_ORG_ID}/create-return`,
      body
    );
    return res.data;
  },
  createShipments: async function createShipments(body) {
    const res = await this.apiClient.post(
      `/v1/returns-portal-service${RETURNS_PORTAL_SERVICE_POSTFIX}/${ROOT_ORG_ID}/create-order`,
      body
    );
    return res.data;
  },
  getShipments: async function getShipments(id) {
    const res = await this.apiClient.get(
      `/v1/returns-portal-service${RETURNS_PORTAL_SERVICE_POSTFIX}/${ROOT_ORG_ID}/order-status/${id}?ts=${Date.now()}`
    );
    return res.data;
  },
  locateAddress: async function locateAddress(zipCode) {
    const queryAddress = qs.stringify(zipCode);
    const res = await this.apiClient.get(
      `/v1/returns-portal-service${RETURNS_PORTAL_SERVICE_POSTFIX}/${ROOT_ORG_ID}/locate-address?${queryAddress}`
    );
    return res.data;
  },
};

export default proxyService;

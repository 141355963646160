import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IPurchaseOrderLine } from 'types/order';
import { compose } from 'recompose';
import { get } from 'lodash';
import {
  ItemHolder,
  Image,
  ContentHolder,
  Description,
  Info,
} from './elements';
import { CURRENCY_SYMBOLS } from '_common/constants/common';
import { WhiteLabelConstants } from '_common/whitelabelConfig';

type Props = WithTranslation & {
  orderLine: IPurchaseOrderLine;
};

const Product = ({ orderLine, t }: Props) => {
  const displayedCurrency = get(
    CURRENCY_SYMBOLS,
    orderLine.priceCurrency || WhiteLabelConstants.DEFAULT_CURRENCY_NAME
  );
  return (
    <ItemHolder>
      <Image imageUrl={orderLine.imageUrl} />
      <ContentHolder>
        <Description>{orderLine.productName}</Description>
        <Info>
          {orderLine.price ? displayedCurrency : null}
          {orderLine.price}
          <br />
          {orderLine.size && t('size')} {orderLine.size}
          <br />
          {orderLine.color && t('color')} {orderLine.color}
        </Info>
      </ContentHolder>
    </ItemHolder>
  );
};

export default compose(withTranslation('returnSubmitted'))(Product);

import proxyService from './yamatoProxyService';
import { TRACKING_EVENT_TYPES } from '../_constants';
import Ratings from 'pages/ratings/routes';

const trackingEventChecker = eventId => TRACKING_EVENT_TYPES[eventId];

const getWhitelabelRoutes = () => Ratings;

export default {
  proxyService,
  trackingEventChecker,
  getWhitelabelRoutes,
};

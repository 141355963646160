import basicTheme from '_common/constants/theme';
import { PAYMENT_TYPES } from '_common/constants/common';

export const TRACKING_EVENT_TYPES = {
  ORDER_RETURN_CREATED: 'ORDER_RETURN_CREATED',
  PARCEL_REACHED_STORE: 'PARCEL_REACHED_STORE',
  CARRIER_COLLECTED: 'CARRIER_COLLECTED',
  BACK_AT_WAREHOUSE: 'BACK_AT_WAREHOUSE',
};
export const PRODUCT_NAME = 'YAMATO_RETURNS_PORTAL';
export const defaultTheme = {
  ...basicTheme,
  assets: {},
  config: {
    css: {
      accentColor: '#000',
    },
    consumerText: {},
    termsAndConditionsLink: '',
  },
};
export const DEFAULT_PAYMENT_TYPE = PAYMENT_TYPES.MERCHANT_PAID;
export const IS_INTEGRATED_JOURNEY_AVAILABLE = true;
export const DEFAULT_CURRENCY_NAME = 'JPY';
